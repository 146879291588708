import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import SideMenu from "../Home/SideMenu";
import { url } from "../API/BaseApi";
import BaseApi from "../API/BaseApi";
import { useDispatch, useSelector } from "react-redux";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import Footer from "../Home/Footer";
import NewsShimmer from "./NewsShimmer";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from 'react-infinite-scroll-component';


const CompanyOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const comSymbolCode = useSelector((state) => state.symbolCode);

  // const { symbolCode, companySymbol } = useParams();

  const { companyName, symbolCode } = useParams();

  // Find the index of the colon (:)
  const colonIndex = symbolCode.indexOf(":");

  // Extract companySymbol from the beginning of the symbolCode until the colon (:)
  const companySymbol =
    colonIndex !== -1 ? symbolCode.substring(0, colonIndex) : symbolCode;

  const [comloading, setComLoading] = useState(false);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [apiCallCounter, setApiCallCounter] = useState(1);
  const [loading, setLoading] = useState(false);

  const [companyOverview, setCompanyOverview] = useState([]);
  const [companyOverview1, setCompanyOverview1] = useState([]);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;

  useEffect(() => {
    const fetchComOverview = async () => {
      try {
        setComLoading(true);

        const formData = new FormData();
        formData.append("start", 0);
        formData.append("symbol", String(symbolCode));
        formData.append("slug", companyName);

        // If comSymbolCode is not available in Redux, try to get it from url
        if (!comSymbolCode) {
          const getSymbolCode = symbolCode;
          if (getSymbolCode) {
            dispatch(setSymbolCode(getSymbolCode));
          }
        }

        const response = await BaseApi.post(url.companyNews, formData);

        if (
          response.data &&
          response.data.getnews &&
          response.data.getnews.length > 0
        ) {
          setCompanyOverview(response.data.getnews);
          setCompanyOverview1(response.data);
          setStartValue(response.data.start);
          setLimitValue(response.data.limit);
          setTotalCount(response.data.count);

          // Update meta tags when the component mounts
          document.title = `${response.data.getnews[0].company_name || "GoldDiscovery.com"
            } Interactive Dashboard - GoldDiscovery.com`;

          const metaTitle = document.querySelector('meta[name="title"]');
          if (metaTitle) {
            metaTitle.content = `${response.data.getnews[0].company_name || "GoldDiscovery.com"
              }   Interactive Dashboard - GoldDiscovery.com`;
          }

          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            if (metaDescription) {
              metaDescription.content = `Drill results, News, Discoveries, Financials, Shareholders, Financials, Insiders for ${response.data.getnews[0].company_name || "GoldDiscovery.com"
                }`;
            }
          }

          const metaKeywords = document.querySelector('meta[name="keywords"]');
          if (metaKeywords) {
            metaKeywords.content = `${response.data.getnews[0].company_name || "GoldDiscovery.com"
              }  Interactive Dashboard - GoldDiscovery.com`;
          }

          // Set the 'darkmode' class on the body
          const bodyElement = document.querySelector("body");
          if (fetchDarkMode) {
            bodyElement.classList.add("darkmode");
          } else {
            bodyElement.classList.remove("darkmode");
          }

          // Clean up when the component unmounts
          return () => {
            document.title = "GoldDiscovery.com";

            if (metaTitle) {
              metaTitle.content = "GoldDiscovery.com";
            }

            if (metaDescription) {
              metaDescription.content = "";
            }

            if (metaKeywords) {
              metaKeywords.content = "";
            }
          };
        } else {
          // Handle the case when response is empty or zero
          // console.log("No data received");
          setHasMore(false); // Stop further infinite scrolling
        }
      } catch (error) {
        setComLoading(false);
        // console.error("Error fetching ComOver Next:", error);
      } finally {
        setComLoading(false);
        setLoading(false);
      }
    };

    fetchComOverview();
  }, []);

  // const fetchComOverNext = async () => {
  //   try {
  //     // if (!totalCount || totalCount === null) {
  //     //   setComLoading(true);
  //     // } else {
  //     //   setLoading(true);
  //     // }
  //     setLoading(true);

  //     const nextValue = Number(startValue) + Number(limitValue);
  //     const formData = new FormData();
  //     formData.append("start", nextValue);
  //     formData.append("symbol", String(symbolCode));
  //     formData.append("slug", companyName);

  //     // If comSymbolCode is not available in Redux, try to get it from url
  //     if (!comSymbolCode) {
  //       const getSymbolCode = symbolCode;
  //       if (getSymbolCode) {
  //         dispatch(setSymbolCode(getSymbolCode));
  //       }
  //     }

  //     const response = await BaseApi.post(url.companyNews, formData);
  //     console.log("CompanyOverview Api", response);

  //     if (response.data && response.data.getnews) {
  //       // Check for duplicates before appending data
  //       // const uniqueNews = response.data.getnews.filter(
  //       //   (newItem) =>
  //       //     !companyOverview.some(
  //       //       (existingItem) => existingItem.id === newItem.id
  //       //     )
  //       // );

  //       setCompanyOverview((prevData) => [
  //         ...prevData,
  //         ...response.data.getnews,
  //       ]);
  //       setCompanyOverview1(response.data);
  //       setStartValue(String(nextValue));
  //       setLimitValue(response.data.limit);
  //       setTotalCount(response.data.count);

  //       // Check if nextValue exceeds the total count or apiCallCount exceeds response count
  //       if (apiCallCounter >= response.data.count) {
  //         setHasMore(false);
  //         setLoading(false);
  //       } else {
  //         // Increment the apiCallCount if conditions are not met
  //         setApiCallCounter((prevCount) => prevCount + 1);
  //       }
  //     } else {
  //       setHasMore(false);
  //       setLoading(false);
  //     }

  //     console.log(
  //       "Start is",
  //       nextValue,
  //       "Limit is",
  //       limitValue,
  //       "Api Count",
  //       apiCallCounter
  //     );
  //   } catch (error) {
  //     console.error("Error fetching ComOver Next:", error);
  //   } finally {
  //     setComLoading(false);
  //     setLoading(false);
  //   }
  // };

  const fetchComOverNext = async () => {
    try {
      setLoading(true);

      const nextValue = Number(startValue) + Number(limitValue);
      const formData = new FormData();
      formData.append("start", nextValue);
      formData.append("symbol", String(symbolCode));
      formData.append("slug", companyName);

      // If comSymbolCode is not available in Redux, try to get it from url
      if (!comSymbolCode) {
        const getSymbolCode = symbolCode;
        if (getSymbolCode) {
          dispatch(setSymbolCode(getSymbolCode));
        }
      }

      const response = await BaseApi.post(url.companyNews, formData);
      // console.log("CompanyOverview Api", response);

      if (response.data && response.data.getnews) {
        setCompanyOverview((prevData) => [
          ...prevData,
          ...response.data.getnews,
        ]);
        setCompanyOverview1(response.data);
        setStartValue(String(nextValue));
        setLimitValue(response.data.limit);
        setTotalCount(response.data.count);

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
          setLoading(false);
        }

        // Increment the apiCallCount regardless of the condition
        // setApiCallCounter((prevCount) => prevCount + 1);
        setApiCallCounter((prevCount) => prevCount + 0.5);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching ComOver Next:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  const GMcolor = (gandm) => {
    const colors = {
      "> 10000": { bgcolor: "#375623", fcolor: "#fff" },
      "1000 - 10000": { bgcolor: "#548235", fcolor: "#fff" },
      "750 - 1000": { bgcolor: "#61953D", fcolor: "#fff" },
      "500 - 750": { bgcolor: "#6DA945", fcolor: "#fff" },
      "250 - 500": { bgcolor: "#81BB59", fcolor: "#fff" },
      "150 - 250": { bgcolor: "#99C979", fcolor: "#fff" },
      "100 - 150": { bgcolor: "#AAD18F", fcolor: "#fff" },
      "75 - 100": { bgcolor: "#C7E1B5", fcolor: "#000" },
      "60 - 75": { bgcolor: "#DDECD4", fcolor: "#000" },
      "50 - 60": { bgcolor: "#EDF5E7", fcolor: "#000" },
      "40 - 50": { bgcolor: "#F4F9F1", fcolor: "#000" },
      "30 - 40": { bgcolor: "#FBF9F3", fcolor: "#000" },
      "20 - 30": { bgcolor: "#F9F6EB", fcolor: "#000" },
      "10 - 20": { bgcolor: "#F9F8DF", fcolor: "#000" },
      "0 - 10": { bgcolor: "#F6F5D2", fcolor: "#000" },
      default: { bgcolor: "#F6F5D2", fcolor: "#000" },
    };

    let range;

    if (gandm > 10000) {
      range = "> 10000";
    } else if (gandm > 1000) {
      range = "1000 - 10000";
    } else if (gandm > 750) {
      range = "750 - 1000";
    } else if (gandm > 500) {
      range = "500 - 750";
    } else if (gandm > 250) {
      range = "250 - 500";
    } else if (gandm > 150) {
      range = "150 - 250";
    } else if (gandm > 100) {
      range = "100 - 150";
    } else if (gandm > 75) {
      range = "75 - 100";
    } else if (gandm > 60) {
      range = "60 - 75";
    } else if (gandm > 50) {
      range = "50 - 60";
    } else if (gandm > 40) {
      range = "40 - 50";
    } else if (gandm > 30) {
      range = "30 - 40";
    } else if (gandm > 20) {
      range = "20 - 30";
    } else if (gandm > 10) {
      range = "10 - 20";
    } else if (gandm > 0) {
      range = "0 - 10";
    } else {
      range = "default";
    }

    return colors[range];
  };

  const navigateToNewsDetail = (slugurl) => {
    navigate(`/news/${slugurl}/`);
    window.location.reload();
  };

  const ref = useRef(null);

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${companyOverview.company_name || "GoldDiscovery.com"
          }  Interactive Dashboard - GoldDiscovery.com`}</title>

        <meta
          name="title"
          content={`${companyOverview.company_name || "GoldDiscovery.com"
            } Interactive Dashboard - GoldDiscovery.com`}
        />

        <meta
          name="description"
          content={`Drill results, News, Discoveries, Financials, Shareholders, Financials, Insiders for ${companyOverview.company_name}`}
        />

        <meta
          name="keywords"
          content={`${companyOverview.company_name || "GoldDiscovery.com"
            } Interactive Dashboard - GoldDiscovery.com`}
        />
        <meta
          property="og:title"
          content={`${companyOverview.company_name || "GoldDiscovery.com"
            } Interactive Dashboard - GoldDiscovery.com`} />
        <meta
          property="og:description"
          content={`Drill results, News, Discoveries, Financials, Shareholders, Financials, Insiders for ${companyOverview.company_name}`} />
        <meta property="og:image" content="https://golddiscovery.com/App-icon.jpg" />
        <meta property="og:image:secure_url" content="https://golddiscovery.com/App-icon.jpg" />
      </Helmet>
      <Header />

      <div className="panel-blk compne">
        <div className="container">
          <div className="panbxs">
            <SideMenu />

            {comloading ? (
              <NewsShimmer />
            ) : (
              <div className="panel_top">
                <div className="panel-b comhead">
                  <div className="panbhead ne-c">
                    <ul className="lab-head">
                      <li>
                        <span
                          className={`label ${symbolCode.endsWith(":AU") ? "labelau" : ""
                            }`}
                        >
                          {companySymbol}
                        </span>
                        <strong>{companyOverview1.company_name}</strong>
                      </li>
                      {companyOverview1.exchange && (
                        <li>
                          <span>{companyOverview1.exchange}</span>
                        </li>
                      )}
                    </ul>
                  </div>

                  <div className="com-list" ref={ref} id="scrollable">
                    <InfiniteScroll
                      hasMore={hasMore}
                      next={fetchComOverNext}
                      dataLength={companyOverview.length}
                      scrollableTarget="scrollable"

                    >
                      {Array.isArray(companyOverview) &&
                        companyOverview.length > 0 ? (
                        companyOverview.map((item, index) => (
                          <div key={index} className="ne-c">
                            <ul className="lab-head">
                              <li>
                                <span
                                  className={`label ${item.symbol_code.endsWith(":AU")
                                    ? "labelau"
                                    : ""
                                    }`}
                                >
                                  {companySymbol}
                                </span>
                                <strong>{item.company_name}</strong>
                              </li>
                              <li>
                                <span>{formatDate(item.datetime)} (ET)</span>
                                <span> ${item.price}</span>
                              </li>
                            </ul>
                            <p
                              onClick={() =>
                                navigateToNewsDetail(item.slug_url)
                              }
                            >
                              {item.news_headline}
                            </p>

                            <div className="nes_num">
                              {Array.isArray(item.lables_id) &&
                                item.lables_id.length > 0 && (
                                  <ul className="news_but">
                                    {item.lables_id.map((label, index) => (
                                      <li key={index}>
                                        {isLoggedIn ||
                                          label.name !== "Potential discovery" ? (
                                          <a
                                            style={{
                                              backgroundColor:
                                                label.name === "Potential discovery"
                                                  ? fetchDarkMode
                                                    ? "#8d6ae4" // Dark mode 
                                                    : "#8d6ae4" // Light mode 
                                                  : label.name === "CapAlert"
                                                    ? fetchDarkMode
                                                      ? "#AA5554" // Dark mode 
                                                      : "#AA5554" // Light mode 
                                                    : fetchDarkMode
                                                      ? "#666666" // Dark mode 
                                                      : "#f0f0f0", // Light mode
                                              color:
                                                label.name ===
                                                  "Potential discovery" || label.name === "CapAlert"
                                                  ? fetchDarkMode
                                                    ? "white" // Dark mode 
                                                    : "white" // Light mode 
                                                  : fetchDarkMode
                                                    ? "white" // Dark mode 
                                                    : "black", // Light mode 
                                            }}
                                          >
                                            {label.name ===
                                              "Potential discovery"
                                              ? "Discovery AI"
                                              : label.name}
                                          </a>
                                        ) : null}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              {item.drill_gm && Number(item.drill_gm) > 0 && (
                                <span
                                  style={{
                                    backgroundColor: GMcolor(item.drill_gm)
                                      .bgcolor,
                                    color: GMcolor(item.drill_gm).fcolor,
                                  }}
                                >
                                  {Number(item.drill_gm).toFixed(0)}
                                </span>
                              )}
                            </div>

                            <span className="dat">{item.datetime}</span>
                          </div>
                        ))
                      ) : (
                        <div className="nodat">
                          <p>No news for this company.</p>
                        </div>
                      )}

                      {loading && (
                        <div className="load_spinner">
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      )}
                    </InfiniteScroll>
                  </div>

                  <div
                    className="uparw"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                    }}
                  >
                    <a>
                      <img src="images/garw.svg"></img>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompanyOverview;
