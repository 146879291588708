import React, { useState, useEffect, useRef } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import Header from "../Header/Header";
import SideMenu from "./SideMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import DisShimmer from "../Components/DisShimmer";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from 'react-infinite-scroll-component';


const Discoveries = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [comloading, setComLoading] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [apiCallCounter, setApiCallCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const [discoveriesData, setDiscoveriesData] = useState([]);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  const [disPollData, setDisPollData] = useState([]);
  const [pollStart, setPollStart] = useState(0);
  const [pollLength, setPollLength] = useState(0);
  const [pollIndicator, setPollIndicator] = useState(0);
  const [visted, setVisted] = useState(0);
  const [vistLength, setVistLength] = useState(0);

  const fetchDarkMode = useSelector((state) => state.darkMode);


  const INTERVAL_TIME = 30000; // 30 seconds

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "Discoveries - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "Discoveries - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "Discoveries - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "Discoveries - GoldDiscovery.com";
    }

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  // fetch Discoveries Page Data
  useEffect(() => {
    const fetchDiscoveriesData = async () => {
      try {
        if (!totalCount || totalCount === null) {
          setComLoading(true);
        } else {
          setLoading(true);
        }
        const formData = new FormData();
        formData.append("start", startValue);

        const response = await BaseApi.post(url.discoveries, formData);
        // console.log("Discoveries Api:", response);
        setDiscoveriesData(response.data.data);
        setLimitValue(response.data.limit);
        setTotalCount(response.data.count);
        localStorage.setItem("maxId", response.data.max);
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
        setComLoading(false);
      }
    };
    fetchDiscoveriesData();
  }, []);

  //

  useEffect(() => {
    const fetchDisPoll = async () => {
      try {
        if (discoveriesData.length > 0) {
          const max = localStorage.getItem("maxId");

          // Check if max is not null, undefined, or empty
          if (max) {
            const formData = new FormData();
            formData.append("recId", Number(max));

            const response = await BaseApi.post(
              url.discoveries_latest_news,
              formData
            );
            // console.log("Polling Discovery Api:", response);

            if (response.data && response.data.data.length > 0) {
              setDisPollData(response.data.data);
              setPollStart(response.data.data.length);
              setPollLength(response.data.data.length);
              setPollIndicator(response.data.data.length);
              setVistLength(response.data.data.length);

              localStorage.setItem("maxId", response.data.max);
            }

            // console.log("MaxId", response.data.max);
          }
        }
      } catch (error) {
        // console.error("Error fetching latest news:", error);
      }
    };

    const intervalId = setInterval(() => {
      fetchDisPoll();
    }, INTERVAL_TIME);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [discoveriesData]);

  //

  const navigateToCompanyOverview = (company_url, symbolCode) => {
    dispatch(setSymbolCode(symbolCode));

    const SymbolCode = symbolCode;
    navigate(`/company/${company_url}/${SymbolCode}`);
    window.location.reload();
  };

  const navigateToNewsDetail = (slugurl) => {
    navigate(`/news/${slugurl}`);
    window.location.reload();
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  const fetchDisNext = async () => {
    try {
      if (!totalCount || totalCount === null) {
        setComLoading(true);
      } else {
        setLoading(true);
      }
      const nextValue = Number(startValue) + Number(limitValue) + pollStart;
      const formData = new FormData();
      formData.append("start", nextValue);

      const response = await BaseApi.post(url.discoveries, formData);
      // console.log("Discovery Next Api:", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        // console.log(
        //   "start is",
        //   nextValue,
        //   "Response Length",
        //   response.data.data.length,
        //   "Api call Count",
        //   apiCallCounter,
        //   "Api Count",
        //   totalCount
        // );
        setDiscoveriesData((prevData) => [...prevData, ...response.data.data]);
        setStartValue(nextValue - pollLength);

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
        } else {
          // Increment the apiCallCount if conditions are not met
          setApiCallCounter((prevCount) => prevCount + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching Discovery news:", error);
    } finally {
      setLoading(false);
    }
  };

  const ref = useRef(null);

  // const ScrollIndicator = () => {
  //   setVisted(visted + pollIndicator);

  //   setTimeout(() => {
  //     setPollIndicator(0);
  //   }, 100);
  // };

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // console.log("poll indicator ", pollIndicator);

      // ScrollIndicator();
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      let Top = e.target.scrollTop;
      if (Top == 0) {
        // ScrollIndicator();
        setVisted(vistLength);

        setTimeout(() => {
          setPollIndicator(0);
        }, 100);
      }
      // console.log("scroll Top", e.target.scrollTop);
    };

    ref.current?.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, [vistLength, visted]);

  return (
    <>
      <Helmet>
        <title>Discoveries - Gold Discovery</title>

        <meta name="title" content="Gold Discovery" />

        <meta name="description" content="Discoveries - GoldDiscovery.com" />
        <meta name="keywords" content="Discoveries - GoldDiscovery.com" />
        <meta
          property="og:title"
          content="Discoveries - GoldDiscovery.com" />
        <meta
          property="og:description"
          content="Discoveries - GoldDiscovery.com" />
        <meta property="og:image" content="https://golddiscovery.com/App-icon.jpg" />
        <meta property="og:image:secure_url" content="https://golddiscovery.com/App-icon.jpg" />

      </Helmet>
      <Header />
      <div className="main-content">
        <div className="panel-blk dis-cover">
          <div className="container">
            <div className="panbxs">
              <SideMenu />
              {comloading ? (
                <DisShimmer />
              ) : (
                <>
                  <div className="panel_top">
                    <div className="panel-b" ref={ref} id="scrollable">
                      <InfiniteScroll
                        hasMore={hasMore}
                        next={fetchDisNext}
                        dataLength={discoveriesData.length}
                        scrollableTarget="scrollable"
                      >
                        {discoveriesData && discoveriesData.length > 0 ? (
                          [...disPollData, ...discoveriesData].map(
                            (item, index) => (
                              <div className="ne-cl" key={index}>
                                <ul className="ne-gry">
                                  <li>Alert</li>
                                  <li>Potential Discovery</li>
                                </ul>
                                <div className="ne-c">
                                  <ul className="lab-head">
                                    <li
                                      onClick={() =>
                                        navigateToCompanyOverview(
                                          item.company_url,
                                          item.symbol_code,
                                          item.company_symbol
                                        )
                                      }
                                    >
                                      <span
                                        className={`label ${item.symbol_code.endsWith(":AU")
                                          ? "labelau"
                                          : ""
                                          }`}
                                      >
                                        {item.company_symbol}
                                      </span>
                                      <strong
                                        onClick={() =>
                                          navigateToCompanyOverview(
                                            item.company_url,
                                            item.symbol_code,
                                            item.company_symbol
                                          )
                                        }
                                      >
                                        {item.company_name}
                                      </strong>
                                    </li>
                                    <li>
                                      <span>${item.price}</span>
                                    </li>
                                  </ul>
                                  <p
                                    onClick={() =>
                                      navigateToNewsDetail(item.slug_url)
                                    }
                                  >
                                    {item.news_headline}
                                  </p>

                                  {item.news_summary &&
                                    item.news_summary.trim().length > 0 && (
                                      <div className="para">
                                        <strong>Discovery Summary</strong>
                                        <p
                                          onClick={() =>
                                            navigateToNewsDetail(
                                              item.news_headline,
                                              item.news_id,
                                              item.symbol_code
                                            )
                                          }
                                        >
                                          {item.news_summary}
                                        </p>
                                      </div>
                                    )}
                                  <ul className="dis">
                                    {item.lables_id &&
                                      item.lables_id.length > 0 ? (
                                      item.lables_id.map((label, index) => (
                                        <li key={index}>
                                          <span
                                            style={{
                                              backgroundColor:
                                                label.name === "Potential discovery"
                                                  ? fetchDarkMode
                                                    ? "#8d6ae4" // Dark mode 
                                                    : "#8d6ae4" // Light mode 
                                                  : label.name === "CapAlert"
                                                    ? fetchDarkMode
                                                      ? "#AA5554" // Dark mode 
                                                      : "#AA5554" // Light mode 
                                                    : fetchDarkMode
                                                      ? "#666666" // Dark mode 
                                                      : "#f0f0f0", // Light mode 
                                              color:
                                                label.name === "Potential discovery" || label.name === "CapAlert"
                                                  ? fetchDarkMode
                                                    ? "white" // Dark mode 
                                                    : "white" // Light mode 
                                                  : fetchDarkMode
                                                    ? "white" // Dark mode 
                                                    : "black", // Light mode 
                                            }}
                                          >
                                            {label.name ===
                                              "Potential discovery"
                                              ? "Discovery AI"
                                              : label.name}
                                          </span>
                                        </li>
                                      ))
                                    ) : (
                                      <li></li>
                                    )}
                                  </ul>
                                  <span className="dat">
                                    {formatDate(item.datetime)} (ET)
                                  </span>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div className="nodat">
                            <p>Currently No Discoveries News Available</p>
                          </div>
                          // <div>No Discoveries News Available</div>
                        )}

                        {loading && (
                          <div className="load_spinner">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        )}
                      </InfiniteScroll>

                      {discoveriesData && discoveriesData.length > 0 && (
                        <div
                          className="uparw"
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToTop();
                          }}
                        >
                          <a>
                            <img src="images/garw.svg"></img>
                            {pollIndicator - visted > 0 && (
                              <span>{pollIndicator - visted}</span>
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Discoveries;
