import { createSlice } from "@reduxjs/toolkit";

const activeSectionSlice = createSlice({
  name: "activeSection",
  initialState: "news",
  reducers: {
    setActiveSection: (state, action) => {
      return action.payload;
    },
  },
});

export const { setActiveSection } = activeSectionSlice.actions;
export default activeSectionSlice.reducer;
