import React from "react";
import {
    ShimmerButton,
    ShimmerTitle,
    ShimmerText,
    ShimmerCircularImage,
    ShimmerThumbnail,
    ShimmerBadge,
    ShimmerTableCol,
    ShimmerTableRow,
  } from "react-shimmer-effects";

const DrillShimmer = () => {
  return (
    <>
      <div classNameName="panel_top">
        <div className="panel-b tab-c shim tshim">
          <div className="calen">
            <a
              className="dat"
              data-bs-toggle="modal"
              data-bs-target="#calend"
            ></a>
          </div>
          <div className="table-responsive kale">
            <table>
              <thead>
                <tr>
                  <th className="label">
                    <span></span>
                  </th>
                  <th className="name"></th>
                  <th className="frm">
                    <span></span>
                  </th>
                  <th className="widh">
                    <span></span>
                  </th>
                  <th className="auq">
                    <span></span>
                  </th>
                  <th className="prim-met">
                    <span></span>
                  </th>
                  <th className="aueq">
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <ShimmerTable row={10} /> */}
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <ShimmerTable row={10} /> */}
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <ShimmerTable row={10} /> */}
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <ShimmerTable row={10} /> */}
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <ShimmerTable row={10} /> */}
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <ShimmerTable row={10} /> */}
                  <td className="label">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                  <td className="name">
                    <strong>
                      <ShimmerButton size="sm" />
                    </strong>
                  </td>
                  <td className="frm">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="widh">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="auq">
                    <ShimmerButton size="sm" />
                  </td>
                  <td className="prim-met">
                    <ul>
                      <li>
                        <a>
                          <ShimmerButton size="sm" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td className="aueq">
                    <span>
                      <ShimmerButton size="sm" />
                    </span>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrillShimmer;
