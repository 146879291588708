import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "../Redux/activeSectionSlice";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Signup from "../Screens/Signup";
import ForgotPassword from "../Screens/ForgotPassword";
import Login from "../Screens/Login";
import { Modal } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { logoutUser } from "../Redux/authSlice";
import { setShowloginmodal } from "../Redux/modalSlice";

const SideMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const activeSection = useSelector((state) => state.activeSection);

  const showloginModal = useSelector((state) => state.modal.showLoginModal);

  // console.log("AlertCount", alertCountNo);

  // const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const [profileModal, setProfileModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const usernameLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("username") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const imageUrlLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("imageurl") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const profileLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("profile") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const alertCountLocal = localStorage.getItem("alertcount");

  // Get values from Redux
  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);
  const usernameRedux = useSelector((state) => state.auth.username);
  const userIdRedux = useSelector((state) => state.auth.userId);
  const imageUrlRedux = useSelector((state) => state.auth.imageUrl);
  const profileRedux = useSelector((state) => state.auth.profile);
  const alertCountRedux = useSelector((state) => state.alert.alertCount);

  // Determine the final values
  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;
  const username = usernameLocal || usernameRedux;
  const userId = userIdLocal || userIdRedux;
  const imageUrl = imageUrlLocal || imageUrlRedux;
  const profile = profileLocal || profileRedux;

  // Use the userId from Redux if it is not 0, otherwise, use the userId from local storage
  const alertCountNo =
    alertCountRedux !== 0 ? alertCountRedux : alertCountLocal;

  // console.log("alertcountLocal", alertCountNo);

  useEffect(() => {
    const bodyElement = document.querySelector("body");

    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }
  }, [fetchDarkMode]);

  const handleLoginModalShow = () => {
    dispatch(setShowloginmodal(true));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  useEffect(() => {
    // Check the state and trigger the handleLoginModalShow if true
    if (showloginModal) {
      handleLoginModalShow();
    }
  }, [showloginModal]);

  const handleSignupModalShow = () => {
    dispatch(setShowloginmodal(false));
    setShowSignupModal(true);
    setShowForgotPasswordModal(false);
  };

  const handleForgotPasswordModalShow = () => {
    dispatch(setShowloginmodal(false));
    setShowSignupModal(false);
    setShowForgotPasswordModal(true);
  };

  const handleModalClose = () => {
    dispatch(setShowloginmodal(false));
    // setShowLoginModal(false);
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  const handleBack = () => {
    dispatch(setShowloginmodal(true));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  const handleSignupSuccess = () => {
    setShowSignupModal(false);
    dispatch(setShowloginmodal(true));
  };

  // const handleHome = () => {
  //   dispatch(setActiveSection("news"));
  //   navigate("/news");
  // };

  // const handleDiscoveries = () => {
  //   if (isLoggedIn) {
  //     // User is logged in, navigate to the "Discoveries" section
  //     dispatch(setActiveSection("discoveries"));
  //     navigate("/discoveries");
  //   } else {
  //     // User is not logged in, show the login modal
  //     handleLoginModalShow();
  //   }
  // };

  // const handleAlerts = () => {
  //   if (isLoggedIn) {
  //     // User is logged in, navigate to the "Discoveries" section
  //     dispatch(setActiveSection("alerts"));
  //     navigate("/alerts");
  //   } else {
  //     // User is not logged in, show the login modal
  //     handleLoginModalShow();
  //   }
  // };

  // const handleSettings = () => {
  //   dispatch(setActiveSection("settings"));
  //   navigate("/settings");
  // };

  const handleHome = () => {
    const newActiveSection = "news";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate("/news");
      window.location.reload();
    }
  };

  const handleDiscoveries = () => {
    const newActiveSection = "discoveries";
    if (isLoggedIn) {
      if (activeSection === newActiveSection) {
        // Reload the page if the section is already active
        window.location.reload();
      } else {
        // User is logged in, navigate to the "Discoveries" section
        dispatch(setActiveSection(newActiveSection));
        navigate("/discoveries");
        window.location.reload();
      }
    } else {
      // User is not logged in, show the login modal
      handleLoginModalShow();
    }
  };

  const handleAlerts = () => {
    const newActiveSection = "alerts";
    if (isLoggedIn) {
      if (activeSection === newActiveSection) {
        // Reload the page if the section is already active
        window.location.reload();
      } else {
        // User is logged in, navigate to the "Alerts" section
        dispatch(setActiveSection(newActiveSection));
        navigate("/alerts");
        window.location.reload();
      }
    } else {
      // User is not logged in, show the login modal
      handleLoginModalShow();
    }
  };

  const handleSettings = () => {
    const newActiveSection = "settings";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      // Navigate to the "Settings" section
      dispatch(setActiveSection(newActiveSection));
      navigate("/settings");
      window.location.reload();
    }
  };

  const handleProfileModal = () => {
    setProfileModal(!profileModal);
  };

  const handleProfilePage = () => {
    dispatch(setActiveSection("edit-profile"));
    navigate("/edit-profile");
    window.location.reload();
  };

  const handleLogout = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    // Disable the submit button
    setIsSubmitting(true);

    const formdata = new FormData();
    formdata.append("user", userId);

    // console.log("formdata", userId);

    BaseApi.post(url.logout, formdata)
      .then((response) => {
        if (response && response.data) {
          // console.log(response);
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful Email Sent (toast success)
              toast.success(response.data.toast);

              dispatch(logoutUser());
              setProfileModal(false);

              // Clear the user-related data from storage
              localStorage.removeItem("isLoggedIn"); //1
              localStorage.removeItem("email"); //2
              localStorage.removeItem("userId"); //3
              localStorage.removeItem("username"); //4
              localStorage.removeItem("imageurl"); //5
              localStorage.removeItem("profile"); //6
              localStorage.removeItem("timezone"); //7
              navigate("/news");
              window.location.reload();

              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
            // console.warn("Unexpected response status:", response.data.status);
          }
        } else {
          // console.error("Unexpected response format:", response);
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        setIsSubmitting(false);

        // console.log("Error:", error);
      });
  };

  const alertCountFormat = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(0) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(0) + 'K';
    } else {
      return number.toString();
    }
  }

  return (
    <>
      <div className="panel-a">
        <ul>
          <li
            className={
              activeSection === "news" ||
                activeSection === "ai-digets" ||
                activeSection === "drill-results" ||
                activeSection === "halts"
                ? "active"
                : ""
            }
            onClick={handleHome}
          >
            <a>
              <img
                src={fetchDarkMode ? "images/homes.svg" : "images/home.svg"}
                alt=""
              />
              <span>Home</span>
            </a>
          </li>
          <li
            className={activeSection === "discoveries" | activeSection === "capalerts" ? "active" : ""}
            onClick={handleDiscoveries}
          >
            <a>
              <img
                src={fetchDarkMode ? "images/pro white.svg" : "images/pro.svg"}
                alt=""
              />
              <span>Premium</span>
            </a>
          </li>
          <li
            className={activeSection === "alerts" ? "active" : ""}
            onClick={handleAlerts}
          >
            <a>
              <div className="al_badge">
                <img
                  src={
                    fetchDarkMode
                      ? "images/thilog _dk.svg"
                      : "images/thilog.svg"
                  }
                  alt=""
                />
                {Number(alertCountNo) > 0 && (
                  <span>{alertCountFormat(Number(alertCountNo))}</span>
                )}
              </div>
              <span>Alerts</span>
            </a>
          </li>
          <li
            className={activeSection === "settings" ? "active" : ""}
            onClick={handleSettings}
          >
            <a>
              <img
                src={fetchDarkMode ? "images/sets.svg" : "images/set.svg"}
                alt=""
              />
              <span>Settings</span>
            </a>
          </li>
          <li>
            <a
              href="https://golddiscovery.com/legacy"
              target="_blank"
              rel="noopener noreferrer"
              className="legcybt"
            >
              Legacy Site
            </a>
          </li>

          {isLoggedIn ? (
            <li className={`user ${profileModal ? "active" : ""}`}>
              <a onClick={handleProfileModal}>
                <img
                  src={
                    profile ? `${imageUrl}/${profile}` : "images/dummy_usr.svg"
                  }
                  alt=""
                />
                <strong>Hi, {username}</strong>
                <span>...</span>
              </a>
              {profileModal && (
                <ul className="logop">
                  <li>
                    <a onClick={handleProfilePage}>My Profile</a>
                  </li>
                  <li>
                    <a onClick={handleLogout} disabled={isSubmitting}>
                      {isSubmitting ? "Logging Out..." : "Logout"}
                    </a>
                  </li>
                </ul>
              )}
            </li>
          ) : (
            <li className="user gusr">
              <a onClick={handleLoginModalShow}>
                <img src="images/dummy_usr.svg" alt="" />
                <strong> Guest </strong>
                <span>...</span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="queries">
        <strong>Questions? E-mail us at</strong>
        <a href="mailto:Luc@GoldDiscovery.com">Luc@GoldDiscovery.com</a>
      </div>
      <>
        <Modal className="logg logf" show={showloginModal}>
          <Modal.Body>
            <Login
              handleSignupModalShow={handleSignupModalShow}
              handleForgotPasswordModalShow={handleForgotPasswordModalShow}
              handleBack={handleBack}
              handleModalClose={handleModalClose}
            />
          </Modal.Body>
        </Modal>

        <Modal className="logg logf" show={showSignupModal}>
          <Modal.Body>
            <Signup
              handleBack={handleBack}
              handleModalClose={handleModalClose}
              handleSignupSuccess={handleSignupSuccess}
            />
          </Modal.Body>
        </Modal>

        <Modal className="logg logf" show={showForgotPasswordModal}>
          <Modal.Body>
            <ForgotPassword
              handleBack={handleBack}
              handleModalClose={handleModalClose}
              handleSuccess={handleSignupSuccess}
            />
          </Modal.Body>
        </Modal>
      </>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default SideMenu;
