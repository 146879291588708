import React, { useState, useEffect, useRef } from "react";
import BaseApi, { url } from "../API/BaseApi";
import { useNavigate } from "react-router-dom";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroller";
import { setAlertCount } from "../Redux/alertSlice";
import InfiniteScroll from 'react-infinite-scroll-component';


const News = ({
  newsData,
  setNewsData,
  limitValue,
  startValue,
  setStartValue,
  setTotalCount,
  loading,
  setLoading,
  setComLoading,
  totalCount,
  selectedLabels,
  pollLength,
  pollStart,
  setPollStart,
  newsPoll,
  pollIndicator,
  setPollIndicator,
  ScrollIndicator,
  vistLength,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const userIdRedux = useSelector((state) => state.auth.userId);

  const userId = userIdLocal || userIdRedux;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const [hasMore, setHasMore] = useState(true);
  const [apiCallCounter, setApiCallCounter] = useState(2);
  const [visted, setVisted] = useState(0);

  // const [loading, setLoading] = useState(false);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;

  useEffect(() => {
    const fetchAlertCount = async () => {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("settings", 1);

        // console.log("userId", userId);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("Notification InitialValue Api:", response);

        if (response && response.data.pushSettings.length > 0) {
          const pushSettings = response.data.pushSettings[0];
          const pushCount = pushSettings.alerts_count;
          dispatch(setAlertCount(pushCount));
          localStorage.setItem("alertCount", pushCount);
        }
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
      }
    };
    fetchAlertCount();
  }, [isLoggedIn]);

  const navigateToCompanyOverview = (company_url, symbolCode) => {
    dispatch(setSymbolCode(symbolCode));

    const SymbolCode = symbolCode;
    navigate(`/company/${company_url}/${SymbolCode}`);
    window.location.reload();

  };

  const navigateToNewsDetail = (slugurl) => {
    navigate(`/news/${slugurl}`);
    window.location.reload();

  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  const GMcolor = (gandm) => {
    const colors = {
      "> 10000": { bgcolor: "#375623", fcolor: "#fff" },
      "1000 - 10000": { bgcolor: "#548235", fcolor: "#fff" },
      "750 - 1000": { bgcolor: "#61953D", fcolor: "#fff" },
      "500 - 750": { bgcolor: "#6DA945", fcolor: "#fff" },
      "250 - 500": { bgcolor: "#81BB59", fcolor: "#fff" },
      "150 - 250": { bgcolor: "#99C979", fcolor: "#fff" },
      "100 - 150": { bgcolor: "#AAD18F", fcolor: "#fff" },
      "75 - 100": { bgcolor: "#C7E1B5", fcolor: "#000" },
      "60 - 75": { bgcolor: "#DDECD4", fcolor: "#000" },
      "50 - 60": { bgcolor: "#EDF5E7", fcolor: "#000" },
      "40 - 50": { bgcolor: "#F4F9F1", fcolor: "#000" },
      "30 - 40": { bgcolor: "#FBF9F3", fcolor: "#000" },
      "20 - 30": { bgcolor: "#F9F6EB", fcolor: "#000" },
      "10 - 20": { bgcolor: "#F9F8DF", fcolor: "#000" },
      "0 - 10": { bgcolor: "#F6F5D2", fcolor: "#000" },
      default: { bgcolor: "#F6F5D2", fcolor: "#000" },
    };

    let range;

    if (gandm > 10000) {
      range = "> 10000";
    } else if (gandm > 1000) {
      range = "1000 - 10000";
    } else if (gandm > 750) {
      range = "750 - 1000";
    } else if (gandm > 500) {
      range = "500 - 750";
    } else if (gandm > 250) {
      range = "250 - 500";
    } else if (gandm > 150) {
      range = "150 - 250";
    } else if (gandm > 100) {
      range = "100 - 150";
    } else if (gandm > 75) {
      range = "75 - 100";
    } else if (gandm > 60) {
      range = "60 - 75";
    } else if (gandm > 50) {
      range = "50 - 60";
    } else if (gandm > 40) {
      range = "40 - 50";
    } else if (gandm > 30) {
      range = "30 - 40";
    } else if (gandm > 20) {
      range = "20 - 30";
    } else if (gandm > 10) {
      range = "10 - 20";
    } else if (gandm > 0) {
      range = "0 - 10";
    } else {
      range = "default";
    }

    return colors[range];
  };

  const fetchNewsNext = async () => {
    try {
      if (!totalCount || totalCount === null) {
        setComLoading(true);
      } else {
        setLoading(true);
      }

      const nextValue = Number(startValue) + Number(limitValue) + pollStart;
      const formData = new FormData();
      formData.append("start", nextValue);
      formData.append("extra", pollLength);

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`);
      // }

      if (selectedLabels.length > 0) {
        const labelsString = selectedLabels.join(",");
        formData.append("labels", labelsString);
      }

      const response = await BaseApi.post(url.miningNews, formData);
      // console.log("News Next Api:", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setNewsData((prevData) => [...prevData, ...response.data.data]);
        // setStartValue(response.data.start);
        setStartValue(nextValue - pollLength);

        setTotalCount(response.data.count);

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
        } else {
          // Increment the apiCallCount if conditions are not met
          setApiCallCounter((prevCount) => prevCount + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching Ai-Digest news:", error);
    } finally {
      setLoading(false);
      setComLoading(false);
    }
  };

  // console.log("Start -poll ", startValue);

  const ref = useRef(null);

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // console.log("poll indicator ", pollIndicator);

      // setVisted(vistLength);

      // setTimeout(() => {
      //   setPollIndicator(0);
      // }, 100);
      // ScrollIndicator();
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      let Top = e.target.scrollTop;
      if (Top == 0) {
        // ScrollIndicator();
        setVisted(vistLength);

        setTimeout(() => {
          setPollIndicator(0);
        }, 100);
      }
      // console.log("scroll Top", e.target.scrollTop);
    };

    ref.current?.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, [vistLength, visted]);

  // console.log(
  //   "poll",
  //   pollIndicator,
  //   "visted",
  //   visted,
  //   "visteLength",
  //   vistLength
  // );
  // console.log("Next API is", newsData)

  // console.log("Total count is", totalCount)

  return (
    <>
      <div className="panel_top">
        <div className="panel-b" ref={ref} id="scrollable">
          <InfiniteScroll
            next={fetchNewsNext}
            hasMore={hasMore}
            dataLength={newsData.length}
            scrollableTarget="scrollable"
          >
            {newsData && newsData.length > 0 ? (
              [...newsPoll, ...newsData].map((item, index) => (
                <div key={index} className="ne-c">
                  <ul className="lab-head">
                    <li
                      onClick={() =>
                        navigateToCompanyOverview(
                          item.company_url,
                          item.symbol_code
                        )
                      }
                    >
                      <span
                        className={`label ${item.symbol_code.endsWith(":AU") ? "labelau" : ""
                          }`}
                      >
                        {item.company_symbol}
                      </span>
                      <strong>{item.company_name}</strong>
                    </li>
                    <li
                      onClick={() =>
                        navigateToCompanyOverview(
                          item.company_url,
                          item.symbol_code
                        )
                      }
                    >
                      <span>{formatDate(item.datetime)} (ET)</span>
                      <span>$ {item.price}</span>
                    </li>
                  </ul>
                  <p onClick={() => navigateToNewsDetail(item.slug_url)}>
                    {item.news_headline}
                  </p>

                  <div className="nes_num">
                    {Array.isArray(item.lables_id) &&
                      item.lables_id.length > 0 && (
                        <ul className="news_but">
                          {item.lables_id.map((label, index) => (
                            <li key={index}>
                              {isLoggedIn ||
                                label.name !== "Potential discovery" && label.name !== "CapAlert" ? (
                                <a
                                  style={{
                                    backgroundColor:
                                      label.name === "Potential discovery"
                                        ? fetchDarkMode
                                          ? "#8d6ae4" // Dark mode 
                                          : "#8d6ae4" // Light mode 
                                        : label.name === "CapAlert"
                                          ? fetchDarkMode
                                            ? "#AA5554" // Dark mode 
                                            : "#AA5554" // Light mode 
                                          : fetchDarkMode
                                            ? "#666666" // Dark mode 
                                            : "#f0f0f0", // Light mode 
                                    color:
                                      label.name === "Potential discovery" || label.name === "CapAlert"
                                        ? fetchDarkMode
                                          ? "white" // Dark mode 
                                          : "white" // Light mode 
                                        : fetchDarkMode
                                          ? "white" // Dark mode 
                                          : "black", // Light mode 
                                  }}
                                >
                                  {label.name === "Potential discovery"
                                    ? "Discovery AI"
                                    : label.name}
                                </a>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      )}

                    {item.drill_gm && Number(item.drill_gm) > 0 && (
                      <span
                        style={{
                          backgroundColor: GMcolor(item.drill_gm).bgcolor,
                          color: GMcolor(item.drill_gm).fcolor,
                        }}
                      >
                        {Number(item.drill_gm).toFixed(0)}
                      </span>
                    )}
                    {/* {item.drill_gm && <span>{item.drill_gm}</span>} */}
                  </div>

                  <span className="dat">{item.datetime}</span>
                </div>
              ))
            ) : (
              // <div>No News Available</div>
              <div className="nodat">
                <p>Currently No News Available</p>
              </div>
            )}

            {loading && (
              <div className="load_spinner">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            )}
          </InfiniteScroll>
          {newsData && newsData.length > 0 && (
            <div
              className="uparw"
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
              }}
            >
              <a>
                <img src="images/garw.svg"></img>
                {pollIndicator - visted > 0 && (
                  <span>{pollIndicator - visted}</span>
                )}
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default News;
