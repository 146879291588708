import React, { useState, useEffect } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Header from "./Header";
import SideMenu from "../Home/SideMenu";
import { setActiveSection } from "../Redux/activeSectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../Home/Footer";
import { Helmet } from "react-helmet";
import DrillShimmer from "../Components/DrillShimmer";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroller";
import CryptoJS from "crypto-js";
import { setShowloginmodal } from "../Redux/modalSlice";
import InfiniteScroll from 'react-infinite-scroll-component';


const DrillResults = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);

  // const isLoggedIn = isLoggedInLocal || isLoggedInRedux;

  const last24HoursStartDate = new Date();
  last24HoursStartDate.setDate(last24HoursStartDate.getDate() - 1);

  const [selectedRow, setSelectedRow] = useState(null);

  const [drillResults, setDrillResults] = useState([]);

  const [comloading, setComLoading] = useState(false);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  const [hasMore, setHasMore] = useState(true);
  const [apiCallCounter, setApiCallCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [drillPoll, setDrillPoll] = useState([]);
  const [pollLength, setPollLength] = useState(0);

  // Check if the session storage has the dates stored
  const storedStartDate = sessionStorage.getItem("startDate");
  const storedEndDate = sessionStorage.getItem("endDate");

  const startDateFromSession = storedStartDate
    ? new Date(storedStartDate)
    : last24HoursStartDate;
  const endDateFromSession = storedEndDate
    ? new Date(storedEndDate)
    : new Date();

  // State to keep track of the original start and end dates
  const [startDate, setStartDate] = useState(startDateFromSession);
  const [endDate, setEndDate] = useState(endDateFromSession);
  const [originalStartDate, setOriginalStartDate] = useState(
    startDateFromSession
  );
  const [originalEndDate, setOriginalEndDate] = useState(endDateFromSession);

  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "Drill-Results - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "Drill-Results - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "Drill-Results - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "Drill-Results - GoldDiscovery.com";
    }

    // Set the 'darkmode' class on the body
    const bodyElement = document.querySelector("body");
    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  useEffect(() => {
    const loadDatesFromSession = () => {
      // Check if the session storage has the dates stored
      const storedStartDate = sessionStorage.getItem("startDate");
      const storedEndDate = sessionStorage.getItem("endDate");

      if (storedStartDate && storedEndDate) {
        setStartDate(new Date(storedStartDate));
        setEndDate(new Date(storedEndDate));
        setOriginalStartDate(new Date(storedStartDate));
        setOriginalEndDate(new Date(storedEndDate));
        setIsDateRangeApplied(true);
      } else {
        const last24HoursStartDate = new Date();
        last24HoursStartDate.setDate(last24HoursStartDate.getDate() - 1);
        // If not stored in session, use the default values
        setStartDate(last24HoursStartDate);
        setEndDate(new Date());
        setOriginalStartDate(last24HoursStartDate);
        setOriginalEndDate(new Date());
        setIsDateRangeApplied(true);

        sessionStorage.setItem("startDate", last24HoursStartDate);
        sessionStorage.setItem("endDate", Date());
      }
    };

    loadDatesFromSession();
  }, []);

  const fetchData = async () => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    try {
      const max = localStorage.getItem("maxId") || 0;

      setComLoading(true);
      setLoading(false);
      const formData = new FormData();
      formData.append("start", startValue);
      formData.append("maxId", max);
      formData.append("from", formatDate(startDate));
      formData.append("to", formatDate(endDate));

      // console.log("from:", formatDate(startDate), "to:", formatDate(endDate));

      const response = await BaseApi.post(url.drillResult, formData);

      // console.log("Drill Results Api:", response);
      setDrillResults(response.data.data);
      setLimitValue(response.data.limit);
      setTotalCount(response.data.total);

      localStorage.setItem("maxId", response.data.max);

      // if (response.data.data.length > 20) {
      //   setHasMore(true);
      // } else {
      //   setHasMore(false);
      // }
    } catch (error) {
      console.error("Error fetching mining news:", error);
    } finally {
      setComLoading(false);
      setLoading(false);
      setHasMore(true);
    }
  };

  //

  const INTERVAL_TIME = 30000; // 30 seconds

  useEffect(() => {
    const fetchDrillPoll = async () => {
      try {
        if (drillResults.length > 0) {
          const max = localStorage.getItem("maxId");

          // Check if max is not null, undefined, or empty
          if (max) {
            const formData = new FormData();
            formData.append("drillRecId", Number(max));

            const response = await BaseApi.post(url.latest_news, formData);
            // console.log("Drill Polling Api:", response);

            if (response.data && response.data.drilldata.length > 0) {
              setDrillPoll(response.data.drilldata);
              setPollLength(response.data.drilldata.length);
              // localStorage.setItem("maxId", response.data.max);
              localStorage.setItem("maxId", response.data.drillMax);
            }

            // console.log(
            //   "MaxId",
            //   response.data.drillMax,
            //   "api data length",
            //   response.data.drilldata.length
            // );
          }
        }
      } catch (error) {
        console.error("Error fetching Drill latest news:", error);
      }
    };

    const intervalId = setInterval(() => {
      fetchDrillPoll();
    }, INTERVAL_TIME);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [drillResults]);

  //

  const fetchDataLast24H = async () => {
    // console.log("Triggered Last 24H");
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    try {
      const last24HoursStartDate = new Date();
      last24HoursStartDate.setDate(last24HoursStartDate.getDate() - 1);

      if (!totalCount || totalCount === null) {
        setComLoading(true);
      } else {
        setLoading(true);
      }
      const formData = new FormData();
      formData.append("start", 0);
      formData.append("from", formatDate(last24HoursStartDate));
      formData.append("to", formatDate(new Date()));

      // console.log("from:", formatDate(startDate), "to:", formatDate(endDate));

      const response = await BaseApi.post(url.drillResult, formData);

      // console.log("Drill Results Last 24H:", response);
      setDrillResults(response.data.data);
      setLimitValue(response.data.limit);
      setTotalCount(response.data.count);

      if (response.data.data.length > 20) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching mining news:", error);
    } finally {
      setComLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isDateRangeApplied) {
      fetchData();
    }
  }, [isDateRangeApplied]);

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const handleApply = () => {
    // Update the original start and end dates when the user clicks "Apply"
    setOriginalStartDate(startDate);
    setOriginalEndDate(endDate);

    sessionStorage.setItem("startDate", startDate);
    sessionStorage.setItem("endDate", endDate);
    // Set isDateRangeApplied to true when the user clicks "Apply"
    setIsDateRangeApplied(true);
    dispatch(setActiveSection("drill-results"));

    // Fetch data with the new date range
    fetchData();
  };

  const handleLast24Hours = () => {
    // Calculate the start and end dates for the last 24 hours
    const last24HoursStartDate = new Date();
    last24HoursStartDate.setDate(last24HoursStartDate.getDate() - 1);
    const last24HoursEndDate = new Date();

    // Update the state with the new dates
    setStartDate(last24HoursStartDate);
    setEndDate(last24HoursEndDate);

    sessionStorage.setItem("startDate", last24HoursStartDate);
    sessionStorage.setItem("endDate", last24HoursEndDate);

    // Set isDateRangeApplied to true when the user clicks "Apply"
    dispatch(setActiveSection("drill-results"));
    // Fetch data with the new date range
    setIsDateRangeApplied(true);

    fetchDataLast24H();
    setComLoading(true);
  };

  const handleRowClick = (index) => {
    setSelectedRow((prevSelectedRow) =>
      prevSelectedRow === index ? null : index
    );
  };

  // Function to clear the start and end dates
  const clearDateRange = () => {
    setStartDate(new Date());
    setEndDate(new Date());

    setIsDateRangeApplied(false);

    sessionStorage.removeItem("startDate");
    sessionStorage.removeItem("endDate");

    // fetchData(); // Fetch data with the default date range
  };

  const GMcolor = (gandm) => {
    const colors = {
      "> 10000": { bgcolor: "#375623", fcolor: "#fff" },
      "1000 - 10000": { bgcolor: "#548235", fcolor: "#fff" },
      "750 - 1000": { bgcolor: "#61953D", fcolor: "#fff" },
      "500 - 750": { bgcolor: "#6DA945", fcolor: "#fff" },
      "250 - 500": { bgcolor: "#81BB59", fcolor: "#fff" },
      "150 - 250": { bgcolor: "#99C979", fcolor: "#fff" },
      "100 - 150": { bgcolor: "#AAD18F", fcolor: "#fff" },
      "75 - 100": { bgcolor: "#C7E1B5", fcolor: "#000" },
      "60 - 75": { bgcolor: "#DDECD4", fcolor: "#000" },
      "50 - 60": { bgcolor: "#EDF5E7", fcolor: "#000" },
      "40 - 50": { bgcolor: "#F4F9F1", fcolor: "#000" },
      "30 - 40": { bgcolor: "#FBF9F3", fcolor: "#000" },
      "20 - 30": { bgcolor: "#F9F6EB", fcolor: "#000" },
      "10 - 20": { bgcolor: "#F9F8DF", fcolor: "#000" },
      "0 - 10": { bgcolor: "#F6F5D2", fcolor: "#000" },
      default: { bgcolor: "#F6F5D2", fcolor: "#000" },
    };

    let range;

    if (gandm > 10000) {
      range = "> 10000";
    } else if (gandm > 1000) {
      range = "1000 - 10000";
    } else if (gandm > 750) {
      range = "750 - 1000";
    } else if (gandm > 500) {
      range = "500 - 750";
    } else if (gandm > 250) {
      range = "250 - 500";
    } else if (gandm > 150) {
      range = "150 - 250";
    } else if (gandm > 100) {
      range = "100 - 150";
    } else if (gandm > 75) {
      range = "75 - 100";
    } else if (gandm > 60) {
      range = "60 - 75";
    } else if (gandm > 50) {
      range = "50 - 60";
    } else if (gandm > 40) {
      range = "40 - 50";
    } else if (gandm > 30) {
      range = "30 - 40";
    } else if (gandm > 20) {
      range = "20 - 30";
    } else if (gandm > 10) {
      range = "10 - 20";
    } else if (gandm > 0) {
      range = "0 - 10";
    } else {
      range = "default";
    }

    return colors[range];
  };

  const navigateToNewsDetail = (slugurl) => {
    navigate(`/news/${slugurl}`);
    window.location.reload();
  };

  const formatApiDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  const fetchDrillNext = async () => {
    // console.log("Fetch Drill Next Triggered");
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    try {
      setLoading(true);

      const pollValue = Number(startValue) + Number(limitValue) + pollLength;

      const nextValue = Number(startValue) + Number(limitValue);

      const formData = new FormData();
      // Check if pollLength is greater than 0 and endDate is today's date
      const todayDate = new Date();
      const isToday = endDate.toDateString() === todayDate.toDateString();

      if (pollLength > 0 && isToday) {
        formData.append("start", pollValue);
      } else {
        formData.append("start", nextValue);
      }

      formData.append("from", formatDate(startDate));
      formData.append("to", formatDate(endDate));

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`);
      // }

      const response = await BaseApi.post(url.drillResult, formData);

      // console.log("DrillFetch Next", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setDrillResults((prevData) => [...prevData, ...response.data.data]);
        setStartValue(String(nextValue));
        setLimitValue(response.data.limit);
        setTotalCount(response.data.count);

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
        } else {
          // Increment the apiCallCount if conditions are not met
          setApiCallCounter((prevCount) => prevCount + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching Drill news:", error);
    } finally {
      setLoading(false);
      setComLoading(false);

      // console.log("start after next", startValue);
    }
  };

  const handleLoginModalShow = () => {
    dispatch(setShowloginmodal(true));
  };

  const handleCloseModal = () => {
    setIsDateRangeApplied(false);
  };

  // console.log(
  //   "Load is",
  //   hasMore,
  //   "start is",
  //   startValue,
  //   "apiCallCounter",
  //   apiCallCounter,
  //   "count",
  //   totalCount
  // );

  return (
    <>
      <Helmet>
        <title>Drill-Results - Gold Discovery</title>

        <meta name="title" content="Gold Discovery" />

        <meta name="description" content="Drill-Results - GoldDiscovery.com" />
        <meta name="keywords" content="Drill-Results - GoldDiscovery.com" />
        <meta
          property="og:title"
          content="Drill-Results - GoldDiscovery.com" />
        <meta
          property="og:description"
          content="Drill-Results - GoldDiscovery.com" />
        <meta property="og:image" content="https://golddiscovery.com/App-icon.jpg" />
        <meta property="og:image:secure_url" content="https://golddiscovery.com/App-icon.jpg" />

      </Helmet>
      <Header />
      <div className="main-content">
        <div className="panel-blk">
          <div className="container">
            <div className="panbxs">
              <SideMenu />
              <div className="panel_top">
                <div className="panel-b tab-c">
                  <div className="calen">
                    <a
                      className="dat"
                      data-bs-toggle="modal"
                      data-bs-target="#calend"
                    >
                      <img src="images/calen.png" alt="" />
                      <strong>
                        {isDateRangeApplied
                          ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                          : `${formatDate(originalStartDate)} - ${formatDate(
                            originalEndDate
                          )}`}
                      </strong>
                    </a>
                  </div>

                  <>
                    {comloading ? (
                      <DrillShimmer />
                    ) : (
                      <div className="table-responsive kale" >
                        <table >
                          <thead>
                            <tr>
                              <th className="label">
                                <span />
                              </th>
                              <th className="name" />
                              <th className="frm">
                                From<span>(m)</span>
                              </th>
                              <th className="widh">
                                Width<span>(m)</span>
                              </th>
                              <th className="auq">
                                AuEq<span>(g/t)</span>
                              </th>
                              <th className="prim-met">
                                Prim<span>metal</span>
                              </th>
                              <th className="aueq">
                                AuEq<span>(g*m)</span>
                              </th>
                            </tr>
                          </thead>

                          {drillResults && drillResults.length > 0 ? (
                            <>
                              <tbody id="scrollable">

                                <InfiniteScroll
                                  next={fetchDrillNext}
                                  hasMore={hasMore}
                                  dataLength={drillResults.length}
                                  scrollableTarget="scrollable"

                                  loader={
                                    <div className="load_spinner">
                                      <Spinner
                                        animation="border"
                                        role="status"
                                      ></Spinner>
                                    </div>
                                  }


                                >
                                  {drillResults.length > 0 &&
                                    [...drillPoll, ...drillResults].map(
                                      (result, index) => (
                                        <div key={index}>
                                          <tr
                                            className={`hig ${selectedRow === index ? "shc" : ""
                                              }`}
                                            key={result.id}
                                            onClick={() =>
                                              handleRowClick(index)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <td
                                              className={`label ${result.drill_symbol.endsWith(
                                                ":AU"
                                              )
                                                ? "labelau"
                                                : ""
                                                }`}
                                            >
                                              <span>
                                                {result.drill_cmpsymbol}
                                              </span>
                                            </td>
                                            <td className="name">
                                              <strong>
                                                {result.drill_company}
                                              </strong>
                                            </td>
                                            <td className="frm">
                                              {/* {result.drill_from} */}
                                              {isNaN(result.drill_from) ||
                                                result.drill_from === "?"
                                                ? 0
                                                : result.drill_from}
                                            </td>
                                            <td className="widh">
                                              {result.drill_meter}
                                            </td>
                                            <td className="auq">
                                              {result.drill_gt}
                                            </td>
                                            <td className="prim-met">
                                              <ul>
                                                <li>
                                                  <a>{result.drill_metal}</a>
                                                </li>
                                              </ul>
                                            </td>
                                            <td className="aueq">
                                              <span
                                                style={{
                                                  backgroundColor: GMcolor(
                                                    result.drill_gm
                                                  ).bgcolor,
                                                  color: GMcolor(
                                                    result.drill_gm
                                                  ).fcolor,
                                                }}
                                              >
                                                {Number(
                                                  result.drill_gm
                                                ).toFixed(0)}
                                              </span>
                                            </td>
                                            <td className="bgc">
                                              <ul className="dril-det">
                                                <li
                                                  onClick={() =>
                                                    navigateToNewsDetail(
                                                      result.slug_url
                                                    )
                                                  }
                                                >
                                                  <a>{result.drill_company}</a>
                                                  <a>Open news release</a>
                                                </li>
                                                <li>
                                                  <span>
                                                    {result.drill_project}
                                                  </span>
                                                  <span>
                                                    {result.drill_country}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span>
                                                    Metals &amp; Grades
                                                  </span>
                                                  <span>
                                                    {formatApiDate(
                                                      result.drill_date
                                                    )}
                                                    (ET)
                                                  </span>
                                                </li>
                                                <li className="whit">
                                                  {result.drill_mandg.map(
                                                    (item) => (
                                                      <a key={item.id}>
                                                        {item.name + " "} (
                                                        {item.types})
                                                        <span>
                                                          : {item.value}
                                                        </span>
                                                      </a>
                                                    )
                                                  )}
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        </div>
                                      )
                                    )}
                                </InfiniteScroll>
                              </tbody>
                            </>
                          ) : (
                            <tbody>
                              <tr>
                                <td className="emt" colSpan="7">
                                  No drill results released on this date
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="calend" aria-modal="true" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="calen">
                <div className="calh">
                  <strong>Please select date range</strong>
                  <a className="clear" onClick={clearDateRange}>
                    Clear
                  </a>
                  <p>Double Click for single date selection (Date required)</p>
                </div>
                <div className="calen-ders">
                  <div>
                    <div>
                      <DateRange
                        editableDateInputs={false}
                        onChange={(item) => {
                          setStartDate(item.selection.startDate);
                          setEndDate(item.selection.endDate);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={[
                          {
                            startDate: startDate,
                            endDate: endDate,

                            key: "selection",
                            key: "selection",
                          },
                        ]}
                        maxDate={new Date()}
                        // showDateDisplay={false}
                        className={"date-range-picker"}
                      />
                    </div>
                  </div>
                </div>
                <ul className="buts">
                  <li>
                    <a
                      className="aply"
                      onClick={handleLast24Hours}
                      data-bs-dismiss="modal"
                    >
                      Last 24 H
                    </a>
                  </li>
                  <li>
                    <a
                      className="last24"
                      onClick={handleApply}
                      data-bs-dismiss="modal"
                    >
                      Apply
                    </a>
                  </li>
                </ul>
                <button
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModal}
                >
                  <span>x</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DrillResults;
