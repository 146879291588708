import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

// Retrieve darkMode value from local storage if available
const getInitialDarkMode = () => {
  const storedDarkMode = localStorage.getItem("darkMode");

  if (storedDarkMode) {
    try {
      // Decrypt the stored data using CryptoJS
      const decryptedDarkMode = CryptoJS.AES.decrypt(
        storedDarkMode,
        process.env.REACT_APP_GOLD
      ).toString(CryptoJS.enc.Utf8);

      // Parse the decrypted data as JSON
      return JSON.parse(decryptedDarkMode);
    } catch (error) {
      console.error("Error decrypting or parsing stored data:", error);
    }
  }

  // Return a default value if decryption or parsing fails
  return true;
};

const darkModeSlice = createSlice({
  name: "darkMode",
  initialState: getInitialDarkMode(),
  reducers: {
    setDarkmode: (state, action) => {
      // Save to local storage whenever the state changes
      const encryptedDarkMode = CryptoJS.AES.encrypt(
        JSON.stringify(action.payload),
        process.env.REACT_APP_GOLD
      ).toString();
      localStorage.setItem("darkMode", encryptedDarkMode);
      return action.payload;
    },
  },
});

export const { setDarkmode } = darkModeSlice.actions;
export default darkModeSlice.reducer;

