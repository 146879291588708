import React from "react";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "react-shimmer-effects";

const DisShimmer = () => {
  return (
    <>
      <div className="panel_top">
        <div className="panel-b shim dshim">
          <div className="ne-cl">
            <ul className="ne-gry">
              <ShimmerButton size="lg" />
            </ul>
            <div className="ne-c">
              <ul className="lab-head">
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                  <strong>
                    <ShimmerButton size="sm" />
                  </strong>
                </li>
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                </li>
              </ul>
              <p>
                <ShimmerButton size="lg" />
              </p>
              <div className="para">
                <strong>
                  <ShimmerButton size="sm" />
                </strong>
                <p>
                  
                  <ShimmerButton size="lg" />
                </p>
              </div>
              <ul className="dis">
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                </li>
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                </li>
              </ul>

              <span className>
                <ShimmerButton size="sm" />
              </span>
            </div>
            <div className="ne-c">
              <ul className="lab-head">
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                  <strong>
                    <ShimmerButton size="sm" />
                  </strong>
                </li>
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                </li>
              </ul>
              <p>
                <ShimmerButton size="lg" />
              </p>
              <div className="para">
                <strong>
                  <ShimmerButton size="sm" />
                </strong>
                <p>
                  
                  <ShimmerButton size="lg" />
                </p>
              </div>
              <ul className="dis">
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                </li>
                <li>
                  <span>
                    <ShimmerButton size="sm" />
                  </span>
                </li>
              </ul>

              <span className>
                <ShimmerButton size="sm" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisShimmer;
