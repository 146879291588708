// alertSlice.js

import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    isAlertModalVisible: false,
    isAlertConfirmed: false,
    alertsDataEmpty: true,
    alertCount: Number(0),
  },
  reducers: {
    triggerAlertModal: (state) => {
      state.isAlertModalVisible = !state.isAlertModalVisible;
    },
    setAlertsDataEmpty: (state, action) => {
      state.alertsDataEmpty = action.payload;
    },
    setAlertCount: (state, action) => {
      state.alertCount = action.payload;
    },
  },
});

export const {
  triggerAlertModal,
  confirmAlert,
  resetAlertConfirmation,
  setAlertsDataEmpty,
  setAlertCount,
} = alertSlice.actions;
export default alertSlice.reducer;

