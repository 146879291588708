import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from "react-dom/client"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // Suppress console warnings in production
// if (process.env.NODE_ENV === "production") {
//   console.warn = function () {};
// }

const root = createRoot(document.getElementById("root")); // Use createRoot
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
