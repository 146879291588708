import React, { useEffect, useState } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { setLoggedIn } from "../Redux/authSlice";
import { useDispatch } from "react-redux";
// import Cookies from "js-cookie";

const Login = ({
  handleSignupModalShow,
  handleForgotPasswordModalShow,
  handleModalClose,
}) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [keepSign, setKeepSign] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  useEffect(() => {
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formSubmitted && !email.trim()) {
      setEmailError("We need your email address to contact you");
    } else if (formSubmitted && !emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  }, [email, formSubmitted]);

  useEffect(() => {
    // Validate password
    if (formSubmitted && !password.trim()) {
      setPasswordError("Please specify your password");
    } else if (formSubmitted && password.length < 6) {
      setPasswordError("Password should be minimum 6 characters");
    } else {
      setPasswordError("");
    }
  }, [password, formSubmitted]);

  const handleKeepSign = () => {
    setKeepSign(!keepSign);
  };

  const handleSubmit = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    // Set formSubmitted to true to trigger error messages
    setFormSubmitted(true);

    // Check if all validations passed before making the API call
    if (!email || !password || password.length < 6) {
      // Log the validation errors to the console
      // console.error("Validation Errors:", {
      //   email,
      //   password,
      // });
      // Any of the validations failed, do not proceed with the API call
      return;
    }

    // Disable the submit button
    setIsSubmitting(true);

    const formdata = new FormData();
    formdata.append("login_email", email);
    formdata.append("login_password", password);

    // console.log("formdata", email, password);

    BaseApi.post(url.login, formdata)
      .then((response) => {
        if (response && response.data) {
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful Login (toast success)
              toast.success(response.data.toast);
              // console.log("Login Success Api", response);

              // Example using CryptoJS for encryption
              const encryptedData = CryptoJS.AES.encrypt(
                "true",
                SECRET_KEY
              ).toString();

              const email = response.data.data.email;
              const encryptedEmail = CryptoJS.AES.encrypt(
                email,
                SECRET_KEY
              ).toString();

              const userId = response.data.data.id;
              const encryptedUserId = CryptoJS.AES.encrypt(
                userId,
                SECRET_KEY
              ).toString();

              const username = response.data.data.username;
              const encryptedUserName = CryptoJS.AES.encrypt(
                username,
                SECRET_KEY
              ).toString();

              const imageurl = response.data.data.imageUrl;
              const encryptedimageUrl = CryptoJS.AES.encrypt(
                imageurl,
                SECRET_KEY
              ).toString();

              const profile = response.data.data.profile;
              const encryptedProfile = CryptoJS.AES.encrypt(
                profile,
                SECRET_KEY
              ).toString();

              const timeZone = JSON.stringify(response.data.data.timezone);
              const encryptedtimeZone = CryptoJS.AES.encrypt(
                timeZone,
                SECRET_KEY
              ).toString();

              // Check if the user chose to "Keep me signed in"
              if (keepSign) {
                // Dispatch the setLoggedIn action
                dispatch(
                  setLoggedIn({
                    isLoggedIn: true,
                    email: response.data.data.email,
                    userName: response.data.data.username,
                    userId: response.data.data.id,
                    imageUrl: response.data.data.imageUrl,
                    profile: response.data.data.profile,
                    timeZone: JSON.stringify(response.data.data.timezone),
                  })
                );

                localStorage.setItem("isLoggedIn", encryptedData); //1
                localStorage.setItem("email", encryptedEmail); //2
                localStorage.setItem("userId", encryptedUserId); //3
                localStorage.setItem("username", encryptedUserName); //4
                localStorage.setItem("imageurl", encryptedimageUrl); //5
                localStorage.setItem("profile", encryptedProfile); //6
                localStorage.setItem("timezone", encryptedtimeZone); //7
                localStorage.setItem("FlagUser", "true");
              } else {
                // Dispatch the setLoggedIn action
                dispatch(
                  setLoggedIn({
                    isLoggedIn: true,
                    email: response.data.data.email,
                    userName: response.data.data.username,
                    userId: response.data.data.id,
                    imageUrl: response.data.data.imageUrl,
                    profile: response.data.data.profile,
                    timeZone: JSON.stringify(response.data.data.timezone),
                  })
                );

                localStorage.setItem("isLoggedIn", encryptedData); //1
                localStorage.setItem("email", encryptedEmail); //2
                localStorage.setItem("userId", encryptedUserId); //3
                localStorage.setItem("username", encryptedUserName); //4
                localStorage.setItem("imageurl", encryptedimageUrl); //5
                localStorage.setItem("profile", encryptedProfile); //6
                localStorage.setItem("timezone", encryptedtimeZone); //7
                localStorage.setItem("FlagUser", "false");
              }

              // window.location.reload();
              handleModalClose();

              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
          }
        } else {
          console.error("Unexpected response format:", response);
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        // API request failed (toast error)
        toast.error("Login failed. Please try again later.");
        // console.log("Error:", error);
      });
  };

  return (
    <>
      <div className="l-frm l_sh">
        <div className="l-frc">
          <h1>Login</h1>
          <div className="lf-cont">
            <div className="lf">
              <input
                type="text"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span className="error">{emailError}</span>
            </div>
            <div className="lf">
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              <span className="error">{passwordError}</span>
            </div>
          </div>
          <ul className="chk-for">
            <li>
              <label>
                <input
                  type="checkbox"
                  checked={keepSign}
                  onChange={handleKeepSign}
                />
                <p>Keep me signed in on this computer</p>
              </label>
            </li>
            <li>
              <a className="frgt" onClick={handleForgotPasswordModalShow}>
                Forgot Password?
              </a>
            </li>
          </ul>
          <button
            className="logbt"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Logging in..." : "Login"}
          </button>
        </div>
        <div className="sign">
          <p>
            Don't have an account?
            <a className="sig ac" onClick={handleSignupModalShow}>
              Sign Up Now
            </a>
          </p>
        </div>
        <button className="close" onClick={handleModalClose}>
          <span>x</span>
        </button>
      </div>
    </>
  );
};

export default Login;
