// modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoginModal: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setShowloginmodal: (state, action) => {
      state.showLoginModal = action.payload;
    },
  },
});

export const { setShowloginmodal } = modalSlice.actions;

export default modalSlice.reducer;

