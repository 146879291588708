import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { url } from "../API/BaseApi";
import BaseApi from "../API/BaseApi";
import Header from "../Header/Header";
import SideMenu from "../Home/SideMenu";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import Footer from "../Home/Footer";
import NewsShimmer from "./NewsShimmer";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";

const NewsDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const { slugurl } = useParams();

  const [newsDetails, setNewsDetails] = useState([]);
  const [comloading, setComLoading] = useState(false);

  const shareUrl = `${url.shareurl}/news/${slugurl}`;

  // console.log("share url is", shareUrl)

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        setComLoading(true);
        const formData = new FormData();
        formData.append("slug", slugurl);

        const response = await BaseApi.post(url.miningInfo, formData);
        // console.log("NewsDetail api:", response);
        setNewsDetails(response.data.success);

        // Set the 'darkmode' class on the body
        const encryptedDarkMode = localStorage.getItem("darkMode");
        const fetchDarkMode = encryptedDarkMode
          ? CryptoJS.AES.decrypt(encryptedDarkMode, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          ) === "true"
          : false;
        const bodyElement = document.querySelector("body");
        if (fetchDarkMode) {
          bodyElement.classList.add("darkmode");
        } else {
          bodyElement.classList.remove("darkmode");
        }

        // Update meta tags
        document.title = `${response.data.success.news_headline || "GoldDiscovery.com"
          } - GoldDiscovery.com`;

        const metaTitle = document.querySelector('meta[name="title"]');

        if (metaTitle) {
          metaTitle.content = `${response.data.success.news_headline} - GoldDiscovery.com`;
        }

        const twimetaTitle = document.querySelector('meta[name="twitter:title"]');

        if (twimetaTitle) {
          twimetaTitle.content = `${response.data.success.news_headline} - GoldDiscovery.com`;
        }

        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );

        const twimetaDescription = document.querySelector(
          'meta[name="twitter:description"]'
        );

        if (metaDescription) {
          const newsStoryPlainText = stripHtmlTags(response.data.success.story);
          const truncatedDescription = newsStoryPlainText.substring(0, 200);
          const cleanedDescription = truncatedDescription
            .replace(/\s+/g, " ")
            .trim();
          metaDescription.content = `${cleanedDescription} - GoldDiscovery.com`;
        }

        if (twimetaDescription) {
          const newsStoryPlainText = stripHtmlTags(response.data.success.story);
          const truncatedDescription = newsStoryPlainText.substring(0, 200);
          const cleanedDescription = truncatedDescription
            .replace(/\s+/g, " ")
            .trim();
          twimetaDescription.content = `${cleanedDescription} - GoldDiscovery.com`;
        }

        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.content = `${response.data.success.news_headline} - GoldDiscovery.com`;
        }



        // Clean up when the component unmounts
        return () => {
          document.title = "GoldDiscovery.com";

          if (metaTitle) {
            metaTitle.content = "GoldDiscovery.com";
          }

          if (metaDescription) {
            metaDescription.content = "";
          }

          if (metaKeywords) {
            metaKeywords.content = "";
          }
        };
      } catch (error) {
        navigate('*');
        // console.error("Error fetching mining news:", error);
      } finally {
        setComLoading(false);
      }
    };

    fetchNewsDetails();
  }, [slugurl]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const newsStoryPlainText = stripHtmlTags(newsDetails.story);
  const truncatedDescription = newsStoryPlainText.substring(0, 200);
  const Description = truncatedDescription.replace(/\s+/g, " ").trim();

  const marketcap = newsDetails.marketcap;

  // Format marketCap based on conditions
  // const formattedMarketCap =
  //   marketcap >= 100000000
  //     ? `$${(marketcap / 100000000).toFixed(1)}M`
  //     : marketcap >= 1000
  //     ? `$${(marketcap / 1000).toFixed(1)}K`
  //     : `$${marketcap}`;

  const formattedMarketCap =
    marketcap >= 1000000
      ? `$${(marketcap / 1000000).toFixed(1)}M`
      : `$${marketcap}`;

  const navigateToCompanyOverview = (company_url, symbolCode) => {
    dispatch(setSymbolCode(symbolCode));

    const SymbolCode = symbolCode;
    navigate(`/company/${company_url}/${SymbolCode}`);
    window.location.reload();
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  return (
    <>
      <Helmet>
        <title>{`${newsDetails.news_headline || "GoldDiscovery.com"
          } - GoldDiscovery.com`}</title>
        <meta
          name="title"
          content={`${newsDetails.news_headline || "GoldDiscovery.com"
            } - GoldDiscovery.com`}
        />

        <meta
          name="description"
          content={`${Description || "GoldDiscovery.com"} - GoldDiscovery.com`}
        />

        <meta
          name="keywords"
          content={`${newsDetails.news_headline || "GoldDiscovery.com"
            } - GoldDiscovery.com`}
        />
        <meta
          property="og:title"
          content={`${newsDetails.news_headline || "GoldDiscovery.com"
            } - GoldDiscovery.com`} />
        <meta
          property="og:description"
          content={`${Description || "GoldDiscovery.com"} - GoldDiscovery.com`} />
        <meta property="og:image" content="https://golddiscovery.com/App-icon.jpg" />
        <meta property="og:image:secure_url" content="https://golddiscovery.com/App-icon.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@golddiscovery" />
        <meta name="twitter:url" content={shareUrl} />
        <meta name="twitter:title" content={`${newsDetails.news_headline || "GoldDiscovery.com"
          } - GoldDiscovery.com`} />
        <meta name="twitter:description" content={`${Description || "GoldDiscovery.com"} - GoldDiscovery.com`} />
        <meta name="twitter:image" content="https://golddiscovery.com/App-icon.jpg" />
        <meta name="twitter:image:alt" content="Gold Discovery" />

      </Helmet>
      <Header />

      <div className="panel-blk news-detail">
        <div className="container">
          <div className="panbxs">
            <SideMenu />
            {comloading ? (
              <NewsShimmer />
            ) : (
              <div className="panel_top">
                <div className="panel-b">
                  <div className="ne-c">
                    <ul className="lab-head">
                      <li
                        onClick={() =>
                          navigateToCompanyOverview(
                            newsDetails.company_url,
                            newsDetails.symbol_code,
                            newsDetails.company_symbol
                          )
                        }
                      >
                        <span
                          className={`label ${newsDetails.symbol_code &&
                            newsDetails.symbol_code.endsWith(":AU")
                            ? "labelau"
                            : ""
                            }`}
                        >
                          {newsDetails.company_symbol}
                        </span>
                        <strong>{newsDetails.company_name}</strong>
                      </li>
                    </ul>
                    <ul className="pri-det">
                      <li>Price: {newsDetails.price}</li>
                      <li>Market Cap: {formattedMarketCap}</li>
                      <li>Exchange: {newsDetails.exchange}</li>
                    </ul>

                    <div className="soc_fle">
                      <span className="dat">
                        {formatDate(newsDetails.datetime)} (ET)
                      </span>
                      <div className="soc_media">
                        <span> Share : </span>

                        <FacebookShareButton url={shareUrl}>
                          <a href="javascript:void(0)">
                            <img src="images/facebook.svg" alt="" />
                          </a>
                        </FacebookShareButton>

                        <TwitterShareButton url={shareUrl}>
                          <a href="javascript:void(0)">
                            <img src="images/twitter.svg" alt="" />
                          </a>
                        </TwitterShareButton>


                        <WhatsappShareButton url={shareUrl}>
                          <a href="javascript:void(0)">
                            <img src="images/whatsapp.svg" alt="" />
                          </a>
                        </WhatsappShareButton>


                        <EmailShareButton url={shareUrl}>
                          <a href="javascript:void(0)">
                            <img src="images/email.svg" alt="" />
                          </a>
                        </EmailShareButton>

                      </div>
                    </div>
                  </div>
                  <div className="conts">
                    <h1>{newsDetails.news_headline}</h1>
                  </div>

                  {newsDetails.news_summary &&
                    newsDetails.news_summary.trim().length > 0 && (
                      <div className="qck">
                        <strong>Quick Read: </strong>
                        <p>{newsDetails.news_summary}</p>
                      </div>
                    )}

                  <div
                    className="news-cont"
                    dangerouslySetInnerHTML={{ __html: newsDetails.story }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NewsDetail;

