import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
const controller = process.env.REACT_APP_CONTROLLER;

const BaseApi = axios.create({
  baseURL: baseURL + controller,
});

export const url = {
  miningNews: process.env.REACT_APP_MINING_NEWS_URL,
  miningInfo: process.env.REACT_APP_MINING_INFO_URL,
  drillResult: process.env.REACT_APP_DRILL_RESULT_URL,
  autoSuggest: process.env.REACT_APP_AUTO_SUGGEST_URL,
  companyNews: process.env.REACT_APP_COMPANY_NEWS_URL,
  companyProfile: process.env.REACT_APP_COMPANY_PROFILE_URL,
  companyDetail: process.env.REACT_APP_COMPANY_DETAIL_URL,
  appSettings: process.env.REACT_APP_APP_SETTINGS_URL,
  latestNews: process.env.REACT_APP_LATEST_NEWS_URL,
  appUser: process.env.REACT_APP_APP_USER_URL,
  notifySettings: process.env.REACT_APP_NOTIFY_SETTINGS_URL,
  notifyStored: process.env.REACT_APP_NOTIFY_STORED_URL,
  delNotifyStored: process.env.REACT_APP_DEL_NOTIFY_STORED_URL,
  auto_suggestion: process.env.REACT_APP_AUTO_SUGGESTION_URL,
  discoveries: process.env.REACT_APP_DISCOVERIES_URL,
  halts: process.env.REACT_APP_HALTS_URL,
  aidigest: process.env.REACT_APP_AIDIGEST_URL,
  register: process.env.REACT_APP_REGISTER_URL,
  login: process.env.REACT_APP_LOGIN_URL,
  logout: process.env.REACT_APP_LOGOUT_URL,
  reset_password: process.env.REACT_APP_RESET_PASSWORD_URL,
  profile: process.env.REACT_APP_PROFILE_URL,
  change_password: process.env.REACT_APP_CHANGE_PASSWORD_URL,
  notification_settings: process.env.REACT_APP_NOTIFICATION_SETTINGS_URL,
  latest_news: process.env.REACT_APP_LATEST_NEWS_URL,
  discoveries_latest_news: process.env.REACT_APP_DISCOVERIES_LATEST_NEWS_URL,
  stored_notifications: process.env.REACT_APP_STORED_NOTIFICATIONS_URL,
  delete_stored_notifications:
    process.env.REACT_APP_DELETE_STORED_NOTIFICATIONS_URL,
  account_terminate: process.env.REACT_APP_ACCOUNT_TERMINATE,
  shareurl:process.env.REACT_APP_SHARE_URL,
  capalerts: process.env.REACT_APP_CAPALERT_URL,
  capalerts_latest_news: process.env.REACT_APP_CAPALERT_LATEST_NEWS_URL,

};

export default BaseApi;

