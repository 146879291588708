import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "../Redux/activeSectionSlice";
import { useNavigate } from "react-router-dom";
import BaseApi, { url } from "../API/BaseApi";
import Signup from "../Screens/Signup";
import ForgotPassword from "../Screens/ForgotPassword";
import Login from "../Screens/Login";
import { Modal } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { setShowloginmodal } from "../Redux/modalSlice";
import { ToastContainer } from "react-toastify";

// import { logoutUser } from "../Redux/authSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const activeSection = useSelector((state) => state.activeSection);

  const showloginModal = useSelector((state) => state.modal.showLoginModal);

  // const alertCountNo = useSelector((state) => state.alert.alertCount);

  // console.log("showloginmodal", showloginModal);

  // const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  // // Get darkMode value from local storage
  // const encryptedDarkMode = localStorage.getItem("darkMode");
  // const fetchDarkMode = encryptedDarkMode
  //   ? CryptoJS.AES.decrypt(encryptedDarkMode, SECRET_KEY).toString(
  //       CryptoJS.enc.Utf8
  //     ) === "true"
  //   : false;

  // Set the 'darkmode' class on the body
  const bodyElement = document.querySelector("body");
  if (fetchDarkMode) {
    bodyElement.classList.add("darkmode");
  } else {
    bodyElement.classList.remove("darkmode");
  }

  // const [profileModal, setProfileModal] = useState(false);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  // const usernameLocal = CryptoJS.AES.decrypt(
  //   localStorage.getItem("username") || "",
  //   SECRET_KEY
  // ).toString(CryptoJS.enc.Utf8);

  // const imageUrlLocal = CryptoJS.AES.decrypt(
  //   localStorage.getItem("imageurl") || "",
  //   SECRET_KEY
  // ).toString(CryptoJS.enc.Utf8);
  // const profileLocal = CryptoJS.AES.decrypt(
  //   localStorage.getItem("profile") || "",
  //   SECRET_KEY
  // ).toString(CryptoJS.enc.Utf8);

  const alertCountLocal = localStorage.getItem("alertcount");
  const alertCountRedux = useSelector((state) => state.alert.alertCount);

  // Get values from Redux
  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);
  const userIdRedux = useSelector((state) => state.auth.userId);

  // const usernameRedux = useSelector((state) => state.auth.username);
  // const imageUrlRedux = useSelector((state) => state.auth.imageUrl);
  // const profileRedux = useSelector((state) => state.auth.profile);

  // Determine the final values
  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;
  const userId = userIdLocal || userIdRedux;
  // Use the userId from Redux if it is not 0, otherwise, use the userId from local storage
  const alertCountNo =
    alertCountRedux !== 0 ? alertCountRedux : alertCountLocal;

  // const username = usernameLocal || usernameRedux;
  // const imageUrl = imageUrlLocal || imageUrlRedux;
  // const profile = profileLocal || profileRedux;

  useEffect(() => {
    const fetchAlertCount = async () => {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("Notification InitialValue Api:", response);

        if (response && response.data.pushSettings.length > 0) {
          const pushSettings = response.data.pushSettings[0];
          const pushCount = pushSettings.alerts_count;
          localStorage.setItem("alertCount", pushCount);
        }
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
      }
    };
    fetchAlertCount();
  }, []);
  // console.log("AlertCount", alertCount);

  const handleLoginModalShow = () => {
    dispatch(setShowloginmodal(true));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  useEffect(() => {
    // Check the state and trigger the handleLoginModalShow if true
    if (showloginModal) {
      handleLoginModalShow();
    }
  }, [showloginModal]);

  const handleSignupModalShow = () => {
    dispatch(setShowloginmodal(false));
    setShowSignupModal(true);
    setShowForgotPasswordModal(false);
  };

  const handleForgotPasswordModalShow = () => {
    dispatch(setShowloginmodal(false));
    setShowSignupModal(false);
    setShowForgotPasswordModal(true);
  };

  const handleModalClose = () => {
    dispatch(setShowloginmodal(false));
    // setShowLoginModal(false);
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  const handleBack = () => {
    dispatch(setShowloginmodal(true));
    setShowSignupModal(false);
    setShowForgotPasswordModal(false);
  };

  const handleSignupSuccess = () => {
    setShowSignupModal(false);
    dispatch(setShowloginmodal(true));
  };

  const handleHome = () => {
    dispatch(setActiveSection("news"));
    navigate("/news");
    window.location.reload();
  };

  const handleDiscoveries = () => {
    if (isLoggedIn) {
      // User is logged in, navigate to the "Discoveries" section
      dispatch(setActiveSection("discoveries"));
      navigate("/discoveries");
      window.location.reload();
    } else {
      // User is not logged in, show the login modal
      handleLoginModalShow();
    }
  };

  const handleAlerts = () => {
    if (isLoggedIn) {
      // User is logged in, navigate to the "alerts" section
      dispatch(setActiveSection("alerts"));
      navigate("/alerts");
      window.location.reload();
    } else {
      // User is not logged in, show the login modal
      handleLoginModalShow();
    }
  };

  const handleSettings = () => {
    dispatch(setActiveSection("settings"));
    navigate("/settings");
    window.location.reload();
  };

  return (
    <>
      <footer className="ap_footer">
        <div className="apf_cont">
          <div className="container">
            <ul>
              <li
                className={activeSection === "news" | activeSection === "ai-digets" | activeSection === "drill-results" | activeSection === "halts" ? "active" : ""}
                onClick={handleHome}
              >
                <a>
                  <img
                    src={fetchDarkMode ? "images/homes.svg" : "images/home.svg"}
                    alt=""
                  />
                  <p>Home</p>
                </a>
              </li>
              <li
                className={activeSection === "discoveries" | activeSection === "capalerts" ? "active" : ""}
                onClick={handleDiscoveries}
              >
                <a>
                  <img
                    src={
                      fetchDarkMode ? "images/pro white.svg" : "images/pro.svg"
                    }
                    alt=""
                  />
                  <p>Premium</p>
                </a>
              </li>
              <li
                className={activeSection === "alerts" ? "active" : ""}
                onClick={handleAlerts}
              >
                <a>
                  <div className="al_badge">
                    <img
                      src={
                        fetchDarkMode
                          ? "images/thilog _dk.svg"
                          : "images/thilog.svg"
                      }
                      alt=""
                    />
                    {Number(alertCountNo) > 0 && (
                      <span>{Number(alertCountNo)}</span>
                    )}
                  </div>
                  <p>Alerts</p>
                </a>
              </li>
              <li
                className={activeSection === "settings" ? "active" : ""}
                onClick={handleSettings}
              >
                <a>
                  <img
                    src={fetchDarkMode ? "images/sets.svg" : "images/set.svg"}
                    alt=""
                  />
                  <p>Settings</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <>
        <Modal className="logg logf" show={showloginModal}>
          <Modal.Body>
            <Login
              handleSignupModalShow={handleSignupModalShow}
              handleForgotPasswordModalShow={handleForgotPasswordModalShow}
              handleBack={handleBack}
              handleModalClose={handleModalClose}
            />
          </Modal.Body>
        </Modal>

        <Modal className="logg logf" show={showSignupModal}>
          <Modal.Body>
            <Signup
              handleBack={handleBack}
              handleModalClose={handleModalClose}
              handleSignupSuccess={handleSignupSuccess}
            />
          </Modal.Body>
        </Modal>

        <Modal className="logg logf" show={showForgotPasswordModal}>
          <Modal.Body>
            <ForgotPassword
              handleBack={handleBack}
              handleModalClose={handleModalClose}
              handleSuccess={handleSignupSuccess}
            />
          </Modal.Body>
        </Modal>
      </>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Footer;
