import React, { useState, useEffect, useRef } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "../Redux/activeSectionSlice";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import Spinner from "react-bootstrap/Spinner";
import { triggerAlertModal, setAlertsDataEmpty } from "../Redux/alertSlice";
import { setAlertCount } from "../Redux/alertSlice";
import { setShowloginmodal } from "../Redux/modalSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;

  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const userIdRedux = useSelector((state) => state.auth.userId);

  const userId = userIdLocal || userIdRedux;

  const fetchDarkMode = useSelector((state) => state.darkMode);
  const isAlertsDataEmpty = useSelector((state) => state.alert.alertsDataEmpty);

  useEffect(() => {
    // Extract the active section from the current URL pathname
    const pathname = location.pathname.substring(1); // Remove leading '/'
    const sections = pathname.split("/");
    const dynamicSection = sections[0] || "news";

    // Check if the second segment is "newsdetail"
    const isNewsDetail = sections.length > 1 && sections[0] === "news";

    // If it's not "newsdetail," consider it as the active section
    dispatch(setActiveSection(isNewsDetail ? "newsdetail" : dynamicSection));
  }, [location.pathname, dispatch]);

  const activeSection = useSelector((state) => state.activeSection);

  // console.log("Active Section", activeSection);

  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [headerName, setHeaderName] = useState("News");

  useEffect(() => {
    if (activeSection === "discoveries") {
      setHeaderName("Discoveries");
    } else {
      setHeaderName("News");
    }

    // Set the 'darkmode' class on the body
    const bodyElement = document.querySelector("body");
    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }
  }, [activeSection]);

  // Create a ref to the search container
  const searchContainerRef = useRef(null);

  useEffect(() => {
    // Add click event listener to the document
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowSearch(false);
        setShowSearchResults(false);

        // Remove 'ove' class from the body
        const bodyElement = document.querySelector("body");
        bodyElement.classList.remove("ove");
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [searchContainerRef, setShowSearch, setShowSearchResults]);

  // // fetch Company names by Search
  // useEffect(() => {
  //   const fetchCompanyNames = async () => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("suggestions", 1);

  //       const response = await BaseApi.post(url.auto_suggestion, formData);
  //       console.log("Company list Api:", response);
  //       setCompanyList(response.data);
  //     } catch (error) {
  //       console.error("Error fetching mining news:", error);
  //     } finally {
  //     }
  //   };
  //   fetchCompanyNames(searchQuery);
  // }, [searchQuery]);

  // fetch Company names by Search

  useEffect(() => {
    const fetchAlertCount = async () => {
      try {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("settings", 1);

        // console.log("userId", userId);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("Notification InitialValue Api:", response);

        if (response && response.data.pushSettings.length > 0) {
          const pushSettings = response.data.pushSettings[0];
          const pushCount = pushSettings.alerts_count;
          dispatch(setAlertCount(pushCount));
          localStorage.setItem("alertCount", pushCount);
        }
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
      }
    };
    fetchAlertCount();
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        setLoading(true);

        if (searchQuery.length >= 3) {
          // Only fetch if the search query has at least 3 characters
          const formData = new FormData();
          formData.append("suggestions", 1);

          const response = await BaseApi.post(url.auto_suggestion, formData);
          // console.log("Company list Api:", response);
          setCompanyList(response.data);
          setShowSearchResults(true); // Show the search results
        } else {
          // If the search query is less than 3 characters, hide the search results
          setShowSearchResults(false);
          setCompanyList([]); // Clear the company list
        }
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyNames(); // Call the function without passing searchQuery as an argument
  }, [searchQuery]);

  // Handler for search input change
  const handleSearchInputChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    setShowSearch(true); // Always set showSearch to true when typing
  };

  // Handler to close the search bar and results
  const handleCloseSearch = () => {
    // Check if the search query is empty or null
    setShowSearch(false);
    setShowSearchResults(false);

    // Remove 'ove' class from the body
    const bodyElement = document.querySelector("body");
    bodyElement.classList.remove("ove");
  };

  // Function to filter the company list based on the search query
  const filterCompanyList = () => {
    if (!Array.isArray(companyList)) {
      return [];
    }

    return companyList.filter(
      (company) =>
        company.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        company.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredCompanyList = filterCompanyList();

  const navigateToCompanyOverview = (company_url, symbolCode) => {
    // console.log("company url is", company_url, symbolCode);
    dispatch(setSymbolCode(symbolCode));

    navigate(`/company/${company_url}/${symbolCode}`);
    window.location.reload();
  };

  const handleNews = () => {
    const newActiveSection = 'news';
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate(`/${newActiveSection}`);
      window.location.reload();
    }
  };

  const handleAiDigest = () => {
    const newActiveSection = "ai-digets";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate("/ai-digets");
      window.location.reload();
    }
  };

  // const handleDrill = () => {
  //   const newActiveSection = "drill-results";
  //   if (activeSection === newActiveSection) {
  //     // Reload the page if the section is already active
  //     window.location.reload();
  //   } else {
  //     dispatch(setActiveSection(newActiveSection));
  //     navigate("/drill-results");
  //   }
  // };

  const handleLoginModalShow = () => {
    dispatch(setShowloginmodal(true));
  };

  const handleDrill = () => {
    const newActiveSection = "drill-results";
    if (isLoggedIn) {
      if (activeSection === newActiveSection) {
        // Reload the page if the section is already active
        window.location.reload();
      } else {
        // User is logged in, navigate to the "Discoveries" section
        dispatch(setActiveSection(newActiveSection));
        navigate("/drill-results");
        window.location.reload();

      }
    } else {
      // User is not logged in, show the login modal
      handleLoginModalShow();
    }
  };

  const handleHalts = () => {
    const newActiveSection = "halts";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate("/halts");
      window.location.reload();
    }
  };

  const handleHome = () => {
    const newActiveSection = "news";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate("/news");
      window.location.reload();
    }
  };

  const handleDiscovery = () => {
    const newActiveSection = "discoveries";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate("/discoveries");
      window.location.reload();
    }
  };

  const handleCapalerts = () => {
    const newActiveSection = "capalerts";
    if (activeSection === newActiveSection) {
      // Reload the page if the section is already active
      window.location.reload();
    } else {
      dispatch(setActiveSection(newActiveSection));
      navigate("/capalerts");
      window.location.reload();
    }
  };

  const logoSrc = fetchDarkMode
    ? `${process.env.PUBLIC_URL}/images/hlogh.svg`
    : `${process.env.PUBLIC_URL}/images/hlog.svg`;

  const backIcon = fetchDarkMode
    ? `${process.env.PUBLIC_URL}/images/Arw_white.svg`
    : `${process.env.PUBLIC_URL}/images/Arwh.svg`;

  // Handler to toggle search results and add/remove class on body
  const handleSearchToggle = (event) => {
    // Prevent the event from propagating to the document
    event.stopPropagation();

    setShowSearch(true);
    setShowSearchResults(true);

    const bodyElement = document.querySelector("body");
    bodyElement.classList.add("ove");
  };

  const handleBack = () => {
    navigate(-1);
    setTimeout(() => {
      window.location.reload();

    }, 100);
  }

  return (
    <>
      <header>
        <div className="hed-bk">
          <div className="container">
            {/* <div className="hed-content" > */}
            <div
              className={`hed-content ${activeSection === "company" || activeSection === "newsdetail"
                ? "h_bck"
                : ""
                }`}
            >
              <div className="h-logo">
                <a onClick={handleHome}>
                  <img src={logoSrc} alt="" />
                </a>
              </div>
              {/* {activeSection === "news" | activeSection === "ai-digets" | activeSection === "drill-results" | activeSection === "halts" && (
                <ul className="htabs">
                  <li
                    className={activeSection === "news" ? "active" : ""}
                  >
                    <a onClick={handleNews}>News</a>
                  </li>
                  <li
                    className={activeSection === "ai-digets" ? "active" : ""}
                    onClick={handleAiDigest}
                  >
                    <a>AI-Digest</a>
                  </li>
                  <li
                    className={activeSection === "drill-results" ? "active" : ""}
                    onClick={handleDrill}
                  >
                    <a>Drill Results</a>
                  </li>
                  <li
                    className={activeSection === "halts" ? "active" : ""}
                    onClick={handleHalts}
                  >
                    <a>Halts</a>
                  </li>
                </ul>
              )} */}

              {activeSection === "discoveries" | activeSection === "capalerts" ? (
                <ul className="htabs premi">
                  <li
                    className={activeSection === "discoveries" ? "active" : ""}
                    onClick={handleDiscovery}
                  >
                    <a>Discoveries</a>
                  </li>
                  <li
                    className={activeSection === "capalerts" ? "active" : ""}
                    onClick={handleCapalerts}
                  >
                    <a>CapAlert</a>
                  </li>
                </ul>
              ) : (
                <ul className="htabs">
                  <li
                    className={activeSection === "news" ? "active" : ""}
                  >
                    <a onClick={handleNews}>News</a>
                  </li>
                  <li
                    className={activeSection === "ai-digets" ? "active" : ""}
                    onClick={handleAiDigest}
                  >
                    <a>AI-Digest</a>
                  </li>
                  <li
                    className={activeSection === "drill-results" ? "active" : ""}
                    onClick={handleDrill}
                  >
                    <a>Drill Results</a>
                  </li>
                  <li
                    className={activeSection === "halts" ? "active" : ""}
                    onClick={handleHalts}
                  >
                    <a>Halts</a>
                  </li>
                </ul>

              )}

              <div className="filter">
                {activeSection === "news" ? (
                  <a data-bs-toggle="modal" data-bs-target="#cofilter">
                    <img src="images/filer.svg" alt="" />
                  </a>
                ) : activeSection === "alerts" ? (
                  isAlertsDataEmpty ? ( // Display this content if alertsData is empty
                    "" // Do not render anything (or render an alternative component)
                  ) : (
                    // Display the "dust" icon if alertsData is not empty
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(triggerAlertModal());
                      }}
                    >
                      <img src="images/dust.svg" alt="" />
                    </a>
                  )
                ) : activeSection === "company" ||
                  activeSection === "newsdetail" ? (
                  <a className="ba_ck" onClick={handleBack}>
                    <img src={backIcon} alt="" />
                  </a>
                ) : (
                  ""
                )}
              </div>

              <div
                className={`ser ${showSearchResults || showSearch ? "show" : ""
                  }`}
                ref={searchContainerRef}
              >
                <input
                  type="text"
                  className="serar"
                  placeholder="Search by company name"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                // onBlur={handleSearchInputBlur}
                />
                <a className="close" onClick={handleCloseSearch}>
                  <img src="images/ckicls.svg" alt="" />
                </a>
                {showSearch && searchQuery.length >= 3 && (
                  <div className="sermb">
                    <p>
                      <strong>Results</strong>
                      <span>
                        {filteredCompanyList.length} result
                        {filteredCompanyList.length !== 1 ? "s" : ""} found
                      </span>
                    </p>
                    {searchQuery.length >= 3 && // Add this condition to display results only when searchQuery length is greater than or equal to 3
                      (loading ? (
                        // Bootstrap Spinner
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <ul>
                          {filteredCompanyList.map((company, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                navigateToCompanyOverview(
                                  company.company_url,
                                  company.symbol
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className={`lab ${company.symbol.endsWith(":AU") ? "labau" : ""
                                  }`}
                              >
                                {company.id}
                              </span>
                              <strong>{company.name}</strong>
                            </li>
                          ))}
                        </ul>
                      ))}
                  </div>
                )}
              </div>
              <div className="lens">
                <a className="search" onClick={handleSearchToggle}>
                  <img src="images/lens.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

