import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { url } from "../API/BaseApi";
import BaseApi from "../API/BaseApi";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import Select from "react-select";
import { logoutUser } from "../Redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";


const EditProfile = () => {
  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userIdLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("userId") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const usernameLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("username") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const useremailLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("email") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const usertimeZoneLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("timezone") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  const profileLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("profile") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const userIdRedux = useSelector((state) => state.auth.userId);
  const usernameRedux = useSelector((state) => state.auth.username);
  const useremailRedux = useSelector((state) => state.auth.email);
  const usertimeZoneRedux = useSelector((state) => state.auth.timezone);
  const profileRedux = useSelector((state) => state.auth.profile);

  const userId = userIdLocal || userIdRedux;
  const initialUsername = usernameLocal || usernameRedux;
  const email = useremailLocal || useremailRedux;
  const initialTimeZone = JSON.parse(
    usertimeZoneLocal || usertimeZoneRedux || "{}"
  );
  const initialProfile = profileLocal || profileRedux;

  const [username, setUsername] = useState(initialUsername);
  const [userError, setUserError] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [profile, setProfile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passOldError, setPassOldError] = useState("");
  const [passError, setPassError] = useState("");
  const [passMatError, setPassMatError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passFormSubmitted, setPassFormSubmitted] = useState(false);
  const [passwordKey, setPasswordKey] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [isSubmitting3, setIsSubmitting3] = useState(false);
  const [showTerminateModal, setShowTerminateModal] = useState(false);

  const [timezoneInputFocused, setTimezoneInputFocused] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(
    "Search for nearest city or country"
  );
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const timezoneOptions = Object.values(timeZone).map((tz) => ({
    value: `${tz.id}`,
    label: `${tz.country} | ${tz.city}`,
  }));

  const formatTimezoneForDisplay = (timezone) => {
    return {
      label: `${timezone.country} | ${timezone.city}`,
      value: timezone.id,
    };
  };

  const initialTimezoneForDisplay = formatTimezoneForDisplay(initialTimeZone);

  // fetch timezones list
  useEffect(() => {
    const fetchTimeZone = async () => {
      try {
        const formData = new FormData();
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);
        // console.log("timeZone Api:", response);
        setTimeZone(response.data.timezones);
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      }
    };
    fetchTimeZone();
  }, []);

  const handleProfileSubmit = (e) => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    setFormSubmitted(true);
    e.preventDefault(); // Prevent form submission

    // Check if all validations passed before making the API call
    if (!username) {
      // Log the validation errors to the console
      console.error("Validation Errors:", {
        username,
      });
      // Any of the validations failed, do not proceed with the API call
      return;
    }

    // Check if selectedTimezone is defined before accessing its properties
    if (selectedTimezone || initialTimeZone) {
      // Convert the selectedTimezone object to the desired format
      const formattedTimezone = selectedTimezone
        ? {
            id: selectedTimezone.value,
            timezone: selectedTimezone.label,
            country: selectedTimezone.label.split(" | ")[0],
            city: selectedTimezone.label.split(" | ")[1],
          }
        : initialTimeZone;

      // Disable the submit button
      setIsSubmitting(true);

      const formdata = new FormData();
      formdata.append("userId", userId);
      formdata.append("email", email);
      formdata.append("username", username || initialUsername); // Use the edited username or the initial one if not edited
      formdata.append("timezone", JSON.stringify(formattedTimezone));
      formdata.append("profile", profile || initialProfile);

      // Log the formdata for debugging purposes
      // console.log("Formdata:", formdata);

      BaseApi.post(url.profile, formdata)
        .then((response) => {
          if (response && response.data) {
            // Check the API response status
            switch (response.data.status) {
              case "success":
                // Successful signup (toast success)
                toast.success(response.data.toast);
                // console.log("Profile Success API", response);
                setIsSubmitting(false);

                // Example using CryptoJS for encryption
                const encryptedData = CryptoJS.AES.encrypt(
                  "true",
                  SECRET_KEY
                ).toString();

                const email = response.data.data.email;
                const encryptedEmail = CryptoJS.AES.encrypt(
                  email,
                  SECRET_KEY
                ).toString();

                const userId = response.data.data.id;
                const encryptedUserId = CryptoJS.AES.encrypt(
                  userId,
                  SECRET_KEY
                ).toString();

                const username = response.data.data.username;
                const encryptedUserName = CryptoJS.AES.encrypt(
                  username,
                  SECRET_KEY
                ).toString();

                const imageurl = response.data.data.imageUrl;
                const encryptedimageUrl = CryptoJS.AES.encrypt(
                  imageurl,
                  SECRET_KEY
                ).toString();

                const profile = response.data.data.profile;
                const encryptedProfile = CryptoJS.AES.encrypt(
                  profile,
                  SECRET_KEY
                ).toString();

                const timeZone = JSON.stringify(response.data.data.timezone);
                const encryptedtimeZone = CryptoJS.AES.encrypt(
                  timeZone,
                  SECRET_KEY
                ).toString();

                localStorage.setItem("isLoggedIn", encryptedData); //1
                localStorage.setItem("email", encryptedEmail); //2
                localStorage.setItem("userId", encryptedUserId); //3
                localStorage.setItem("username", encryptedUserName); //4
                localStorage.setItem("imageurl", encryptedimageUrl); //5
                localStorage.setItem("profile", encryptedProfile); //6
                localStorage.setItem("timezone", encryptedtimeZone); //7

                break;
              case "warning":
                // Warning (toast warning)
                toast.warning(response.data.toast);
                break;
              case "error":
                // Error (toast error)
                toast.error(response.data.toast);
                break;
              default:
                console.warn(
                  "Unexpected response status:",
                  response.data.status
                );
            }
            setIsSubmitting(false);
          } else {
            console.error("Unexpected response format:", response);
          }
        })
        .catch((error) => {
          // API request failed (toast error)
          toast.error("Request failed. Please try again later.");
          setIsSubmitting(false);

          // console.log("Error:", error);
        });
    } else {
      console.error("Selected timezone is undefined");
      // Handle the case where selectedTimezone is undefined, e.g., show an error message
      setIsSubmitting(false);
    }
  };

  const handlePasswordSubmit = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting2) {
      return;
    }

    setPassFormSubmitted(true);
    // Set formSubmitted to true to trigger error messages

    // Check if all validations passed before making the API call
    if (
      !oldPassword ||
      !password ||
      password.length < 6 ||
      password !== confirmPassword
    ) {
      console.error("Validation Errors:");

      // Log the validation errors to the console
      // console.error("Validation Errors:", {
      //   oldPassword,
      //   password,
      //   confirmPassword,
      // });

      // Any of the validations failed, do not proceed with the API call
      return;
    }

    // Disable the submit button
    setIsSubmitting2(true);

    const formdata = new FormData();
    formdata.append("userId", userId);
    formdata.append("newPassword", password);

    formdata.append("confirmPassword", confirmPassword);

    formdata.append("oldPassword", oldPassword);

    BaseApi.post(url.change_password, formdata)
      .then((response) => {
        if (response && response.data) {
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // password Change (toast success)
              toast.success(response.data.toast);
              // console.log("Password Change Success API", response);
              setIsSubmitting2(false);

              // Clear password fields
              setOldPassword("");
              setPassword("");
              setConfirmPassword("");

              // Reset form submission state
              setPassFormSubmitted(false);

              // Increment the key to force re-rendering of password fields
              setPasswordKey((prevKey) => prevKey + 1);
              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
          }
          setIsSubmitting2(false);
        } else {
          console.error("Unexpected response format:", response);
          setIsSubmitting2(false);
        }
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        setIsSubmitting2(false);

        // console.log("Error:", error);
      });
  };

  useEffect(() => {
    // Validate username
    const spaceCheck = /\s/;

    if (formSubmitted) {
      if (!username.trim()) {
        setUserError("Please specify your name");
      } else if (spaceCheck.test(username)) {
        setUserError("Usernames cannot contain spaces");
      } else {
        setUserError("");
      }
    }
  }, [username, formSubmitted]);

  useEffect(() => {
    // Validate old password
    if (passFormSubmitted && !oldPassword.trim()) {
      setPassOldError("Please specify your old password");
    } else if (passFormSubmitted && oldPassword.length < 6) {
      setPassOldError("Password should be minimum 6 characters");
    } else {
      setPassOldError("");
    }
  }, [oldPassword, passFormSubmitted]);

  useEffect(() => {
    // Validate password
    if (passFormSubmitted && !password.trim()) {
      setPassError("Please specify your new password");
    } else if (passFormSubmitted && password.length < 6) {
      setPassError("Password should be minimum 6 characters");
    } else {
      setPassError("");
    }
  }, [password, passFormSubmitted]);

  useEffect(() => {
    // Validate confirmPassword
    if (
      passFormSubmitted &&
      password !== confirmPassword &&
      confirmPassword.trim()
    ) {
      setPassMatError("Please enter the same value again");
    } else {
      setPassMatError("");
    }
  }, [password, confirmPassword, passFormSubmitted]);

  const handleNavigate = () => {
    navigate("/news");
    window.location.reload();    
  };

  const handleTerminate = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    // Disable the submit button
    setIsSubmitting(true);

    const formdata = new FormData();
    formdata.append("userId", userId);

    // console.log("userId is ", userId);

    BaseApi.post(url.account_terminate, formdata)
      .then((response) => {
        if (response && response.data) {
          // console.log(response);
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful Email Sent (toast success)
              toast.success(response.data.toast);
              // console.log("Reset Password API", response);

              setIsSubmitting3(false);

              dispatch(logoutUser());

              // Clear the user-related data from storage
              localStorage.removeItem("isLoggedIn"); //1
              localStorage.removeItem("email"); //2
              localStorage.removeItem("userId"); //3
              localStorage.removeItem("username"); //4
              localStorage.removeItem("imageurl"); //5
              localStorage.removeItem("profile"); //6
              localStorage.removeItem("timezone"); //7

              setTimeout(() => {
                handleNavigate();
              }, [100]);
              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
          }
          setIsSubmitting3(false);
        } else {
          console.error("Unexpected response format:", response);
          setIsSubmitting3(false);
        }
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        setIsSubmitting3(false);

        // console.log("Error:", error);
      });
  };

  const backIcon = fetchDarkMode
    ? `${process.env.PUBLIC_URL}/images/Arw_white.svg`
    : `${process.env.PUBLIC_URL}/images/Arwh.svg`;


  const handleTerminateClick = () => {
    // Show the termination confirmation modal
    setShowTerminateModal(true);
  };

  const handleTerminateConfirm = () => {
    // Proceed with termination API
    handleTerminate();

    // Close the modal
    setShowTerminateModal(false);
  };

  const handleTerminateCancel = () => {
    // Close the modal
    setShowTerminateModal(false);
  };

  const handleBack = () => {
    navigate(-1);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  return (
    <>
      <div className="main-content">
        <div className="edit-blk">
          <div className="container">
            <div className="edit-cont">
              <div className="head_bcp">
                <h1>Edit Profile</h1>
                <a className="ba_ck" onClick={handleBack}>
                  <img src={backIcon} alt="" />
                </a>
              </div>
              <div className="frm-blk">
                <div className="_form">
                  <form>
                    <div className="ptex">
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />

                      <span className="error">{userError}</span>
                    </div>
                    <div className="ptex">
                      <p className="read-only">{email}</p>
                    </div>
                    <div className="selectin">
                      <Select
                        options={timezoneOptions}
                        value={
                          selectedTimezone ||
                          (timezoneInputFocused
                            ? null
                            : initialTimezoneForDisplay)
                        }
                        onChange={(selectedOption) =>
                          setSelectedTimezone(selectedOption)
                        }
                        onFocus={() => {
                          setPlaceholderText(
                            "Search for nearest city or country"
                          );
                          setTimezoneInputFocused(true);
                        }}
                        onBlur={() => setTimezoneInputFocused(false)}
                        isSearchable
                        placeholder={placeholderText}
                      />
                    </div>
                    <div className="upld">
                      <input
                        type="file"
                        onChange={(e) => setProfile(e.target.files[0])}
                      />
                      <p>
                        {profile
                          ? profile.name
                          : initialProfile || "no file chosen"}
                      </p>

                      <span>Choose File</span>
                    </div>
                    <input
                      type="submit"
                      value="Edit your account"
                      className="submit"
                      onClick={handleProfileSubmit}
                    />
                  </form>
                </div>
                <div className="_form">
                  <div className="ptex">
                    <input
                      key={passwordKey}
                      type="text"
                      placeholder="Old Password"
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}
                    />
                    <span className="error">{passOldError}</span>
                  </div>
                  <div className="ptex">
                    <input
                      key={passwordKey}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <div
                      className="eye"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <img src="images/show.svg" alt="show" />
                      ) : (
                        <img src="images/hide.svg" alt="hide" />
                      )}
                    </div>
                    <span className="error">{passError}</span>
                  </div>
                  <div className="ptex">
                    <input
                      key={passwordKey}
                      type="text"
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    <span className="error">{passMatError}</span>
                  </div>
                  <input
                    type="submit"
                    defaultValue="Edit your password"
                    className="submit"
                    onClick={handlePasswordSubmit}
                  />
                </div>
              </div>
              <div className="termi_blk">
                <p>
                  If you wish to terminate your account, you can easily delete
                  your profile here. Note that this is a permanent action and
                  your account cannot be recovered once your termination has
                  been confirmed.
                </p>
                <div className="_btn">
                  <a
                    // onClick={handleTerminate}
                    onClick={handleTerminateClick}
                    className="btk"
                    // disabled={isSubmitting3}
                  >
                    {/* {isSubmitting3 ? "Terminating..." : "Terminate"} */}
                    Terminate
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <Modal
          show={showTerminateModal}
          onHide={handleTerminateCancel}
          centered
          className="setmod al"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the account?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={handleTerminateConfirm}
            >
              YES
            </button>
            <button
              className="btn btn-secondary"
              onClick={handleTerminateCancel}
            >
              NO
            </button>
          </Modal.Footer>
        </Modal>
      </>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default EditProfile;
