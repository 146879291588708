import React from "react";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerTableCol,
  ShimmerTableRow,
} from "react-shimmer-effects";

const NewsShimmer = () => {
  const shimmerColor = "#757272"; // Default color
  const shimmerBgColor = "#404040"; // Default background color

  return (
    <>
      <div className="panel_top">
        <div className="panel-b shim">
          <div className="ne-c">
            <ul className="lab-head">
              <li>
                <span>
                  <ShimmerButton
                    size="sm"
                    color={shimmerColor}
                    bgColor={shimmerBgColor}
                  />
                </span>
                <strong>
                  <ShimmerButton size="lg" />
                </strong>
              </li>
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <span>
                  <ShimmerButton size="sm" />
                </span>
              </li>
            </ul>
            <>
              <ShimmerTitle line={3} variant="secondary" />
            </>
            <ul className="news_but">
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
            </ul>
            <span className="dat">
              <ShimmerButton size="sm" />
            </span>
          </div>
          <div className="ne-c">
            <ul className="lab-head">
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <strong>
                  <ShimmerButton size="lg" />
                </strong>
              </li>
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <span>
                  <ShimmerButton size="sm" />
                </span>
              </li>
            </ul>
            <p>
              <ShimmerTitle line={3} variant="secondary" />
            </p>
            <ul className="news_but">
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
            </ul>
            <span className="dat">
              <ShimmerButton size="sm" />
            </span>
          </div>
          <div className="ne-c">
            <ul className="lab-head">
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <strong>
                  <ShimmerButton size="lg" />
                </strong>
              </li>
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <span>
                  <ShimmerButton size="sm" />
                </span>
              </li>
            </ul>
            <p>
              <ShimmerTitle line={3} variant="secondary" />
            </p>
            <ul className="news_but">
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
            </ul>
            <span className="dat">
              <ShimmerButton size="sm" />
            </span>
          </div>
          <div className="ne-c">
            <ul className="lab-head">
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <strong>
                  <ShimmerButton size="lg" />
                </strong>
              </li>
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <span>
                  <ShimmerButton size="sm" />
                </span>
              </li>
            </ul>
            <p>
              <ShimmerTitle line={3} variant="secondary" />
            </p>
            <ul className="news_but">
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
            </ul>
            <span className="dat">
              <ShimmerButton size="sm" />
            </span>
          </div>
          {/* <div className="ne-c">
            <ul className="lab-head">
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <strong>
                  <ShimmerButton size="lg" />
                </strong>
              </li>
              <li>
                <span>
                  <ShimmerButton size="sm" />
                </span>
                <span>
                  <ShimmerButton size="sm" />
                </span>
              </li>
            </ul>
            <p>
              <ShimmerTitle line={3} variant="secondary" />
            </p>
            <ul className="news_but">
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
              <li>
                <a>
                  <ShimmerButton size="sm" />
                </a>
              </li>
            </ul>
            <span className="dat">
              <ShimmerButton size="sm" />
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default NewsShimmer;
