// authSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  email: "",
  username: "",
  userId: null,
  imageUrl: "",
  profile: "",
  timeZone: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = true;
      state.email = action.payload.email;
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.imageUrl = action.payload.imageUrl;
      state.profile = action.payload.profile;
      state.timeZone = action.payload.timeZone;
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
      state.email = "";
      state.username = "";
      state.userId = null;
      state.imageUrl = "";
      state.profile = "";
      state.timeZone = [];
    },
  },
});

export const { setLoggedIn, setLoggedOut } = authSlice.actions;
export default authSlice.reducer;

export const logoutUser = () => (dispatch) => {
  // Clear the user-related data from storage
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("email");
  localStorage.removeItem("userId");
  localStorage.removeItem("username");
  localStorage.removeItem("imageurl");
  localStorage.removeItem("profile");
  localStorage.removeItem("timezone");

  sessionStorage.removeItem("isLoggedIn");
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("userId");
  sessionStorage.removeItem("username");
  sessionStorage.removeItem("imageurl");
  sessionStorage.removeItem("profile");
  sessionStorage.removeItem("timezone");

  // Update the state to reflect the logged-out state
  dispatch(setLoggedOut());
};
