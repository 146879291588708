import React, { useEffect, useState } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import { toast } from "react-toastify";

const ForgotPassword = ({ handleBack, handleModalClose, handleSuccess }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formSubmitted && !email.trim()) {
      setEmailError("We need your email address to contact you");
    } else if (formSubmitted && !emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  }, [email, formSubmitted]);

  const handleSubmit = () => {
    // Disable the submit button to prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    setFormSubmitted(true);

    if (!email) {
      // console.error("Validation Errors:", {
      //   email,
      // });
      // Any of the validations failed, do not proceed with the API call
      return;
    }

    // Disable the submit button
    setIsSubmitting(true);

    const formdata = new FormData();
    formdata.append("email", email);

    // console.log("formdata", email);

    BaseApi.post(url.reset_password, formdata)
      .then((response) => {
        if (response && response.data) {
          // console.log(response);
          // Check the API response status
          switch (response.data.status) {
            case "success":
              // Successful Email Sent (toast success)
              toast.success(response.data.toast);
              // console.log("Reset Password API", response);
              handleSuccess();
              break;
            case "warning":
              // Warning (toast warning)
              toast.warning(response.data.toast);
              break;
            case "error":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            case "danger":
              // Error (toast error)
              toast.error(response.data.toast);
              break;
            default:
              console.warn("Unexpected response status:", response.data.status);
          }
        } else {
          console.error("Unexpected response format:", response);
        }

        setIsSubmitting(false);
      })
      .catch((error) => {
        // API request failed (toast error)
        toast.error("Request failed. Please try again later.");
        setIsSubmitting(false);
        // console.log("Error:", error);
      });
  };

  return (
    <>
      <div className="l-frm for-gt">
        <div className="l-frc">
          <h1>Forgot your password?</h1>
          <p>
            Reset your password with a device you've recently used to access
            GoldDiscovery to avoid a temporary security restriction.
          </p>
          <div className="lf-cont">
            <div className="lf">
              <input
                type="text"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span className="error">{emailError}</span>
            </div>
          </div>
          <button
            className="logbt subt"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submiting..." : "Submit"}
          </button>
        </div>
        <div className="sign">
          <p>Having trouble resetting your password?</p>
        </div>
        <button className="mbk bck" onClick={handleBack}>
          <img src="images/arw.svg" alt="" />
        </button>
        <button className="close" onClick={handleModalClose}>
          <span>x</span>
        </button>
      </div>
    </>
  );
};

export default ForgotPassword;
