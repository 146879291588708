import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./Home/Home";
import CompanyOverview from "./Components/CompanyOverview";
import NewsDetail from "./Components/NewsDetail";
import AiDigest from "./Header/AiDigest";
import DrillResults from "./Header/DrillResults";
import Halts from "./Header/Halts";
import Alerts from "./Home/Alerts";
import Settings from "./Home/Settings";
import Discoveries from "./Home/Discoveries";
import EditProfile from "./Components/EditProfile";
import NotFound from "./Components/NotFound";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import BaseApi, { url } from "./API/BaseApi";
import ReactGA from "react-ga";
import CapAlerts from "./Home/CapAlerts";

function App() {
  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  useEffect(() => {
    const bodyElement = document.querySelector("body");

    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }
  }, [fetchDarkMode]);

  // Decrypt local storage data
  const isLoggedInLocal = CryptoJS.AES.decrypt(
    localStorage.getItem("isLoggedIn") || "",
    SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  const isLoggedInRedux = useSelector((state) => state.auth.isLoggedIn);

  const isLoggedIn = isLoggedInLocal || isLoggedInRedux;

  useEffect(() => {
    let gaProperties; // Declare gaProperties outside the try block

    const fetchGA = async () => {
      try {
        const formData = new FormData();
        formData.append("settings", 1);

        const response = await BaseApi.post(url.appSettings, formData);

        if (response && response.data) {
          gaProperties = response.data.gaProperties;
        }
      } catch (error) {
        // console.error("Error fetching :", error);
      }
    };

    fetchGA().then(() => {
      if (gaProperties) {
        ReactGA.initialize(gaProperties);
        ReactGA.pageview(document.location.pathname);
      }
    });
  }, []);

  return (
    <BrowserRouter basename="">
      <Routes>
        <Route index element={<Navigate to="/news" replace />} />
        <Route path="/news" element={<Home />} />
        <Route
          path="company/:companyName/:symbolCode/"
          element={<CompanyOverview />}
        />
        <Route path="news/:slugurl/" element={<NewsDetail />} />
        <Route path="/ai-digets" element={<AiDigest />} />
        <Route path="/drill-results" element={<DrillResults />} />
        <Route path="/halts" element={<Halts />} />

        {/* Check isLoggedIn for specific components */}
        <Route
          path="/discoveries"
          element={
            isLoggedIn ? <Discoveries /> : <Navigate to="/news" replace />
          }
        />
        <Route
          path="/capalerts"
          element={
            isLoggedIn ? <CapAlerts /> : <Navigate to="/news" replace />
          }
        />
        <Route
          path="/alerts"
          element={isLoggedIn ? <Alerts /> : <Navigate to="/news" replace />}
        />
        <Route
          path="/edit-profile"
          element={
            isLoggedIn ? <EditProfile /> : <Navigate to="/news" replace />
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
