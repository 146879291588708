import React, { useState, useEffect, useRef } from "react";
import BaseApi from "../API/BaseApi";
import { url } from "../API/BaseApi";
import Header from "./Header";
import SideMenu from "../Home/SideMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { setSymbolCode } from "../Redux/symbolCodeSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Home/Footer";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import NewsShimmer from "../Components/NewsShimmer";
import Spinner from "react-bootstrap/Spinner";
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from 'react-infinite-scroll-component';


const Halts = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const SECRET_KEY = process.env.REACT_APP_GOLD;

  const fetchDarkMode = useSelector((state) => state.darkMode);

  const [haltsData, setHaltsData] = useState([]);

  const [comloading, setComLoading] = useState(false);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [apiCallCounter, setApiCallCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const [haltsPollData, setHaltsPollData] = useState([]);
  const [pollStart, setPollStart] = useState(0);
  const [pollLength, setPollLength] = useState(0);
  const [pollIndicator, setPollIndicator] = useState(0);
  const [visted, setVisted] = useState(0);
  const [vistLength, setVistLength] = useState(0);

  const INTERVAL_TIME = 30000; // 30 seconds

  useEffect(() => {
    // Update meta tags when the component mounts
    document.title = "Halts - GoldDiscovery.com";

    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
      metaTitle.content = "Halts - GoldDiscovery.com";
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "Halts - GoldDiscovery.com";
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = "Halts - GoldDiscovery.com";
    }

    // Set the 'darkmode' class on the body
    const bodyElement = document.querySelector("body");
    if (fetchDarkMode) {
      bodyElement.classList.add("darkmode");
    } else {
      bodyElement.classList.remove("darkmode");
    }

    // Clean up when the component unmounts
    return () => {
      document.title = "GoldDiscovery.com";

      if (metaTitle) {
        metaTitle.content = "GoldDiscovery.com";
      }

      if (metaDescription) {
        metaDescription.content = "";
      }

      if (metaKeywords) {
        metaKeywords.content = "";
      }
    };
  }, []);

  // fetch Halts Page Data
  useEffect(() => {
    const fetchHaltsPage = async () => {
      try {
        if (!totalCount || totalCount === null) {
          setComLoading(true);
        } else {
          setLoading(true);
        }
        const formData = new FormData();
        formData.append("start", startValue);

        const response = await BaseApi.post(url.halts, formData);
        // console.log("Halts Api:", response);
        setHaltsData(response.data.data);
        setLimitValue(response.data.limit);
        setTotalCount(response.data.count);
        localStorage.setItem("maxId", response.data.max);
      } catch (error) {
        // console.error("Error fetching mining news:", error);
      } finally {
        setComLoading(false);
      }
    };
    fetchHaltsPage();
  }, []);

  //

  useEffect(() => {
    const fetchHaltsPoll = async () => {
      try {
        if (haltsData.length > 0) {
          const max = localStorage.getItem("maxId");

          // Check if max is not null, undefined, or empty
          if (max) {
            const formData = new FormData();
            formData.append("recId", Number(max));

            const response = await BaseApi.post(url.latest_news, formData);
            // console.log("Polling Halts Api:", response);

            if (response.data && response.data.halts.length > 0) {
              setHaltsPollData(response.data.halts);
              setPollStart(response.data.halts.length);
              setPollLength(response.data.halts.length);
              setPollIndicator(response.data.halts.length);
              setVistLength(response.data.halts.length);

              localStorage.setItem("maxId", response.data.max);
            }

            // console.log("MaxId", response.data.max);
          }
        }
      } catch (error) {
        // console.error("Error fetching latest news:", error);
      }
    };

    const intervalId = setInterval(() => {
      fetchHaltsPoll();
    }, INTERVAL_TIME);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [haltsData]);

  //

  const navigateToCompanyOverview = (company_url, symbolCode) => {
    dispatch(setSymbolCode(symbolCode));

    const SymbolCode = symbolCode;
    navigate(`/company/${company_url}/${SymbolCode}`);
    window.location.reload();
  };

  const navigateToNewsDetail = (slugurl) => {
    navigate(`/news/${slugurl}`);
    window.location.reload();
  };

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );

    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const formattedDateWithDash = `${year}-${month}-${day}`;

    return `${formattedDateWithDash} ${timePart}`;
  };

  const fetchHaltNext = async () => {
    // console.log("FetchAiHalt Triggered");
    try {
      if (!totalCount || totalCount === null) {
        setComLoading(true);
      } else {
        setLoading(true);
      }
      const nextValue = Number(startValue) + Number(limitValue) + pollStart;
      const formData = new FormData();
      formData.append("start", nextValue);

      const response = await BaseApi.post(url.halts, formData);
      // console.log("Halts Next Api:", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {

        setHaltsData((prevData) => [...prevData, ...response.data.data]);
        setStartValue(nextValue - pollLength);

        // Check if nextValue exceeds the total count or apiCallCount exceeds response count
        if (apiCallCounter >= response.data.count) {
          setHasMore(false);
        } else {
          // Increment the apiCallCount if conditions are not met
          setApiCallCounter((prevCount) => prevCount + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      // console.error("Error fetching Ai-Digest news:", error);
    } finally {
      setLoading(false);
    }
  };

  const ref = useRef(null);

  // const ScrollIndicator = () => {
  //   setVisted(visted + pollIndicator);

  //   setTimeout(() => {
  //     setPollIndicator(0);
  //   }, 100);
  // };

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // console.log("poll indicator ", pollIndicator);

      // ScrollIndicator();
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      let Top = e.target.scrollTop;
      if (Top == 0) {
        // ScrollIndicator();
        setVisted(vistLength);

        setTimeout(() => {
          setPollIndicator(0);
        }, 100);
      }
      // console.log("scroll Top", e.target.scrollTop);
    };

    ref.current?.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, [vistLength, visted]);

  return (
    <>
      <Helmet>
        <title>Halts - Gold Discovery</title>

        <meta name="title" content="Gold Discovery" />

        <meta name="description" content="Halts - GoldDiscovery.com" />
        <meta name="keywords" content="Halts - GoldDiscovery.com" />
        <meta
          property="og:title"
          content="Halts - GoldDiscovery.com" />
        <meta
          property="og:description"
          content="Halts - GoldDiscovery.com" />
        <meta property="og:image" content="https://golddiscovery.com/App-icon.jpg" />
        <meta property="og:image:secure_url" content="https://golddiscovery.com/App-icon.jpg" />

      </Helmet>
      <Header />
      <div className="main-content">
        <div className="panel-blk">
          <div className="container">
            <div className="panbxs">
              <SideMenu />
              {comloading ? (
                <NewsShimmer />
              ) : (
                <>
                  <div className="panel_top">
                    <div className="panel-b halts" ref={ref} id="scrollable">
                      <InfiniteScroll
                        next={fetchHaltNext}
                        hasMore={hasMore}
                        dataLength={haltsData.length}
                        scrollableTarget="scrollable"
                      >
                        {haltsData && haltsData.length > 0 ? (
                          [...haltsPollData, ...haltsData].map(
                            (item, index) => (
                              <div className="ne-c" key={index}>
                                <ul className="lab-head">
                                  <li
                                    onClick={() =>
                                      navigateToCompanyOverview(
                                        item.company_url,
                                        item.symbol_code,
                                        item.company_symbol
                                      )
                                    }
                                  >
                                    <span
                                      className={`label ${item.symbol_code.endsWith(":AU")
                                          ? "labelau"
                                          : ""
                                        }`}
                                    >
                                      {item.company_symbol}
                                    </span>
                                    <strong>{item.company_name}</strong>
                                  </li>
                                  <li
                                    onClick={() =>
                                      navigateToCompanyOverview(
                                        item.company_url,
                                        item.symbol_code,
                                        item.company_symbol
                                      )
                                    }
                                  >
                                    <span>
                                      {formatDate(item.datetime)} (ET)
                                    </span>
                                    <span>${item.price}</span>
                                  </li>
                                </ul>
                                <p
                                  onClick={() =>
                                    navigateToNewsDetail(item.slug_url)
                                  }
                                >
                                  {item.halts}
                                </p>
                              </div>
                            )
                          )
                        ) : (
                          // <div>No Halts News Available</div>
                          <div className="nodat">
                            <p>Currently No News Available</p>
                          </div>
                        )}

                        {loading && (
                          <div className="load_spinner">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        )}
                      </InfiniteScroll>

                      {haltsData && haltsData.length > 0 && (
                        <div
                          className="uparw"
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToTop();
                          }}
                        >
                          <a>
                            <img src="images/garw.svg"></img>
                            {pollIndicator - visted > 0 && (
                              <span>{pollIndicator - visted}</span>
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Halts;
