
import { createSlice } from '@reduxjs/toolkit';

const symbolCodeSlice = createSlice({
  name: 'symbolCode',
  initialState: '',
  reducers: {
    setSymbolCode: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSymbolCode } = symbolCodeSlice.actions;
export default symbolCodeSlice.reducer;
